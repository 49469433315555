import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import {
  getSelectedMachine,
  getSelectedMachineConfiguration,
  getSelectedMachineSensorComparisonSensorData,
  getSelectedMachineSensors,
  getSelectedMetrics
} from 'redux/machines/selectors';
import { getData, getLoadingState } from 'redux/rootSelectors';
import { getBatchRecordTimeFrame, getCommonLoadingState, isSuccess } from 'helpers/utils';
import {
  addSensorsForComparison,
  loadComparisonData,
  loadMachine,
  loadMachineStatusMetrics,
  resetMachineState
} from 'redux/machines/actions';
import {
  setIsBatchRecordsDetails,
  setTimeWindow,
  setTimeWindowRange,
  showMachineState
} from 'redux/ui/settings/actions';
import { getSensorSettingByIds } from 'redux/ui/settings/selectors';
import { getBatchMachineRange, getSelectedBatchRecord } from '../../../redux/batch/selectors';

export const useSensorComparisonConfig = () => {
  const { machineId, start: batchStart, end: batchEnd } = useSelector(getBatchMachineRange);

  return { machineId, batchStart, batchEnd };
};

export const useSensorComparisonData = () => {
  const dispatch = useDispatch();
  const { machineId, batchStart, batchEnd } = useSensorComparisonConfig();
  const [selectedSensors, setSelectedSensors] = useState([]);
  const machineLoadingState = useSelector(compose(getLoadingState, getSelectedMachine));
  const machineMetricsLoadingState = useSelector(compose(getLoadingState, getSelectedMetrics));
  const sensorHistoryLoadingState = useSelector(compose(getLoadingState, getSelectedMachineSensorComparisonSensorData));
  const batch = useSelector(getSelectedBatchRecord);
  const loadingState = getCommonLoadingState([
    machineLoadingState,
    machineMetricsLoadingState,
    sensorHistoryLoadingState
  ]);
  const configuration = useSelector(getSelectedMachineConfiguration);
  const sensors = useSelector(compose(getData, getSelectedMachineSensors));
  const sensorSettings = useSelector(state => getSensorSettingByIds(state, machineId, 'batch-sensor-comparison'));
  const shouldShowMachineState = (sensorSettings || {}).showMachineState || false;

  useEffect(() => {
    dispatch(loadMachine(machineId));

    return () => dispatch(resetMachineState());
  }, [machineId, dispatch]);

  useEffect(() => {
    if (!isSuccess(machineLoadingState.status) || !selectedSensors.length) {
      return;
    }

    const validSensorTypes = selectedSensors.reduce((acc, sensor) => {
      const metrics = (configuration || {}).metrics || [];
      const isValidSensor = metrics.some(metric => (metric.is_custom ? metric.custom_type : metric.type) === sensor);
      return [...acc, ...(isValidSensor ? [sensor] : [])];
    }, []);

    if (validSensorTypes.length) {
      const { from, to } = getBatchRecordTimeFrame(batchStart, batchEnd);
      dispatch(setIsBatchRecordsDetails(true, batch.details.id));
      dispatch(setTimeWindow(from, to));
      dispatch(setTimeWindowRange(false));
      dispatch(addSensorsForComparison(validSensorTypes));
      dispatch(loadMachineStatusMetrics(machineId));
      dispatch(loadComparisonData(null));
    }
  }, [
    machineLoadingState,
    selectedSensors,
    machineId,
    batchStart,
    batchEnd,
    configuration,
    batch.details.id,
    dispatch
  ]);

  const handleCheckboxClick = () => {
    dispatch(showMachineState(machineId, 'batch-sensor-comparison', !shouldShowMachineState));
  };

  return {
    sensors,
    loadingState,
    selectedSensors,
    setSelectedSensors,
    shouldShowMachineState,
    handleCheckboxClick
  };
};

export default useSensorComparisonData;
