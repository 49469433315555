import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getGraphsTemplateById,
  loadSensorAverage,
  loadSensorHistory,
  loadStatusHistory
} from 'redux/graphTemplates/actions';
import {
  getDateRange,
  getLineStyle,
  PERIOD_TYPE_CUSTOM_RANG,
  PERIOD_TYPE_LAST_8_HOURS,
  periodTypeList
} from 'attrs/chartsTemplate';
import { getGraphsTemplatesReducerState } from 'redux/rootSelectors';
import { getMachineStatusProps, isNumber, isSuccess } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { getMillSensor } from 'attrs/sensorType';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDateDialog from 'components/CustomDateDialog';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { MACHINE_STATUS_SIMPLE_MAP } from 'attrs/notifications';
import {
  calculateValue,
  determineDirection,
  findClosestStatusDate,
  findSmallestInitialIntervalRecord,
  setMarkAreaVisibility,
  sideSpace,
  statusMap,
  updateSeriesWithMarkArea
} from 'helpers/chartUtils';
import PropTypes from 'prop-types';
import ScreenWarning from 'components/ScreenWarning';
import ChartTemplateFooterContainer from './ChartTemplateFooter';

// Total height of chart grid in pixels
const chartPixelHeight = 410;
// Total height of chart from top of container to botton of chart grid in pixels
const chartPixelHeightFromTop = 490;
// Total height of chart container in pixels
const chartPixelHeightContainer = 580;

/**
 * A specific function to return a date formatted, can be used only in this component
 * @param {*} value
 * @returns
 */
function getFormattedDate(value) {
  const date = new Date(value);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${day}/${month}/${year}\n${hours}:${minutes}:${seconds}`;
}

export default function ChartTemplateDetails({
  graphTemplate,
  interval,
  setInterval,
  customPeriodStart,
  setCustomPeriodStart,
  customPeriodEnd,
  setCustomPeriodEnd,
  chartRef,
  footerRef
}) {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const xAxisDefaultValue = [
    {
      type: 'time',
      boundaryGap: false,
      axisLabel: {
        formatter: '{dd}/{MM}/{yyyy}\n{hh}:{mm}:{ss}'
      }
    }
  ];

  const yAxisDefaultValue = [
    {
      data: []
    }
  ];

  const seriesDefaultValue = [
    {
      data: [],
      type: 'line'
    }
  ];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { templateId } = useParams();

  const [resumedSensorMap, setResumedSensorMap] = useState(new Map());
  const [sensorVisibilityList, setSensorVisibilityList] = useState([]);
  const [loadSensorParamsList, setLoadSensorParamsList] = useState([]);
  const [loadStatusParam, setLoadStatusParam] = useState();

  const loadSensorHistoryStatusEqual = (oldObj, newObj) =>
    oldObj?.loadSensorHistoryStatus?.status === newObj?.loadSensorHistoryStatus?.status;
  const { loadSensorHistoryStatus } = useSelector(getGraphsTemplatesReducerState, loadSensorHistoryStatusEqual);

  const loadSensorAverageStatusEqual = (oldObj, newObj) =>
    oldObj?.loadSensorAverageStatus?.status === newObj?.loadSensorAverageStatus?.status;
  const { loadSensorAverageStatus } = useSelector(getGraphsTemplatesReducerState, loadSensorAverageStatusEqual);

  const sensorHistoryResponseEqual = (oldObj, newObj) => {
    const sameSize = oldObj?.sensorHistoryResponse?.size === newObj?.sensorHistoryResponse?.size;
    const biggerThenZero = oldObj?.sensorHistoryResponse?.size > 0;
    const equalsObj = Array.from(oldObj?.sensorHistoryResponse?.keys()).every(
      key =>
        JSON.stringify(oldObj?.sensorHistoryResponse?.get(key) || '') ===
        JSON.stringify(newObj?.sensorHistoryResponse?.get(key) || '')
    );
    return sameSize && equalsObj && !biggerThenZero;
  };
  const { sensorHistoryResponse } = useSelector(getGraphsTemplatesReducerState, sensorHistoryResponseEqual);

  const sensorAverageResponseEqual = (oldObj, newObj) => {
    const sameSize = oldObj?.sensorAverageResponse?.size === newObj?.sensorAverageResponse?.size;
    const biggerThenZero = oldObj?.sensorAverageResponse?.size > 0;
    const equalsObj = Array.from(oldObj?.sensorAverageResponse?.keys()).every(
      key =>
        JSON.stringify(oldObj?.sensorAverageResponse?.get(key) || '') ===
        JSON.stringify(newObj?.sensorAverageResponse?.get(key) || '')
    );
    return sameSize && equalsObj && !biggerThenZero;
  };
  const { sensorAverageResponse } = useSelector(getGraphsTemplatesReducerState, sensorAverageResponseEqual);

  const { loadStatusHistoryStatus, statusHistoryResponse } = useSelector(getGraphsTemplatesReducerState);
  const [sensorHistoryMap, setSensorHistoryMap] = useState(new Map());
  const [sensorAverageMap, setSensorAverageMap] = useState(new Map());
  const [statusHistoryList, setStatusHistoryList] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [series, setSeries] = useState([]);

  const didMount = useRef(false);
  const [openCustomDateDialog, setOpenCustomDateDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [checkedShowMachineStatus, setCheckedShowMachineStatus] = useState(true);
  const [dataZoomRange, setDataZoomRange] = useState({ start: 0, end: 100 });
  const [forceReload, setForceReload] = useState(false);

  /**
   * A specific function to find sensor attributes, can be used only in this component
   * @param {*} resultsParam
   * @returns
   */
  function findSensorAttributes(resultsParam) {
    return resultsParam.map(result => {
      const sensor = graphTemplate.sensors.find(s => s.id === result.sensorId);
      const millSensor = getMillSensor(sensor.type);
      const name = t(millSensor?.name || sensor?.name);
      const unit = millSensor?.unit || sensor?.custom_unit || sensor?.unit || 'n/a';
      return {
        name,
        unit,
        color: sensor.line_color,
        value: result.closestEntry.v
      };
    });
  }

  useEffect(() => {
    setYAxis([]);
    setSeries([]);
    setLoading(true);
    // check if graphTemplate is already loaded
    if (graphTemplate && graphTemplate.id) {
      let intervalSelection = interval;
      if (!intervalSelection) {
        intervalSelection = graphTemplate?.period_type || PERIOD_TYPE_LAST_8_HOURS;
        setInterval(intervalSelection);
      }
      const [pastDate, currentDate] =
        intervalSelection !== PERIOD_TYPE_CUSTOM_RANG
          ? getDateRange(intervalSelection)
          : [customPeriodStart, customPeriodEnd];
      const tmpResumedSensorMap = new Map();
      const tmpSensorVisibilityList = [];
      const tempSensorParamsList = [];
      const tempStatusParam = {
        machineId: graphTemplate.machine_id,
        from: pastDate,
        to: currentDate
      };
      graphTemplate.sensors.forEach((s, i) => {
        const millSensor = getMillSensor(s?.type);
        const name = millSensor?.name || s?.name;
        const unit = millSensor?.unit || s?.custom_unit || s?.unit || 'n/a';

        const resumedSensor = {
          sensorId: s?.id,
          name,
          isCustom: s?.is_custom,
          type: s?.type,
          customIconType: s?.custom_icon_type,
          unit,
          productionLine: s?.production_line,
          machineName: s?.machine_name,
          lineColor: s?.line_color,
          lineStyle: s?.line_style,
          lineThickness: s?.line_thickness
        };
        tmpResumedSensorMap.set(s?.id, resumedSensor);
        tmpSensorVisibilityList.push(true);
        tempSensorParamsList.push({
          sensorId: s?.id,
          machineId: s?.machine_id,
          from: pastDate,
          to: currentDate,
          unit,
          sensor_type: s?.type
        });

        const transLatedName = t(`${name}`);
        const yAxisSensor = {
          sensorId: s?.id,
          min: isNumber(s?.min_scale) ? s?.min_scale : 'dataMin',
          max: isNumber(s?.max_scale) ? s?.max_scale : 'dataMax',
          type: 'value',
          name: unit,
          show: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: s?.line_color
            }
          },
          nameRotate: 45,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold'
          },
          axisLabel: {
            show: true
          }
        };
        const seriesSensor = {
          sensorId: s?.id,
          name: transLatedName,
          type: 'line',
          symbol: 'none',
          color: s?.line_color,
          lineStyle: {
            width: s?.line_thickness || 1,
            type: getLineStyle(s?.line_style),
            simplify: true,
            opacity: 1
          },
          yAxisIndex: i,
          data: [],
          show: true,
          itemStyle: { opacity: 1 },
          progressive: 5000,
          progressiveThreshold: 10000,
          large: true,
          largeThreshold: 2000
        };
        setYAxis(prevYAxis => (!prevYAxis?.length ? [yAxisSensor] : [...prevYAxis, yAxisSensor]));
        setSeries(prevSeries => (!prevSeries?.length ? [seriesSensor] : [...prevSeries, seriesSensor]));
      });
      setResumedSensorMap(tmpResumedSensorMap);
      setSensorVisibilityList(tmpSensorVisibilityList);
      setLoadSensorParamsList(tempSensorParamsList);
      setLoadStatusParam(tempStatusParam);
    }
  }, [setLoadSensorParamsList, setLoadStatusParam, graphTemplate, forceReload]);

  // when changes period type
  useEffect(() => {
    if (
      interval &&
      (interval !== PERIOD_TYPE_CUSTOM_RANG || (customPeriodStart && customPeriodEnd)) &&
      graphTemplate &&
      graphTemplate.id
    ) {
      setLoading(true);
      setDataZoomRange({ start: 0, end: 100 });
      if (didMount.current) {
        const [pastDate, currentDate] =
          interval !== PERIOD_TYPE_CUSTOM_RANG ? getDateRange(interval) : [customPeriodStart, customPeriodEnd];
        const tempSensorParamsList = [];
        const tempStatusParam = {
          machineId: graphTemplate.machine_id,
          from: pastDate,
          to: currentDate
        };
        graphTemplate.sensors.forEach(s => {
          const unit = s?.is_custom ? 'n/a' : s?.unit;
          tempSensorParamsList.push({
            sensorId: s?.id,
            machineId: s?.machine_id,
            from: pastDate,
            to: currentDate,
            unit,
            sensor_type: s?.type
          });
        });
        setLoadSensorParamsList(tempSensorParamsList);
        setLoadStatusParam(tempStatusParam);
      } else {
        // This code will run only on initial mount
        didMount.current = true;
      }
    }
  }, [interval, customPeriodStart, customPeriodEnd, graphTemplate]);

  useEffect(() => {
    dispatch(loadSensorHistory(loadSensorParamsList));
    dispatch(loadSensorAverage(loadSensorParamsList));
  }, [dispatch, loadSensorHistory, loadSensorAverage, loadSensorParamsList]);

  useEffect(() => {
    if (isSuccess(loadSensorHistoryStatus.status)) {
      setSensorHistoryMap(sensorHistoryResponse);
    }
  }, [loadSensorHistoryStatus, sensorHistoryResponse]);

  useEffect(() => {
    if (isSuccess(loadSensorAverageStatus.status)) {
      setSensorAverageMap(sensorAverageResponse);
    }
  }, [loadSensorAverageStatus, sensorAverageResponse]);

  useEffect(() => {
    if (sensorHistoryMap.size && graphTemplate && graphTemplate.id) {
      sensorHistoryMap.forEach((value, key) => {
        const foundSensor = graphTemplate?.sensors?.find(s => s.id === key);
        const machineId = foundSensor?.machine_id;
        const type = foundSensor?.type;
        const customUnit = foundSensor?.custom_unit;
        const unit = foundSensor?.unit;
        const millSensor = getMillSensor(type);
        const unitDef = millSensor?.unit || customUnit || unit || 'n/a';

        const newValue =
          value?.map(m => ({
            name: new Date(m.t).toISOString(),
            value: [new Date(m.t).toISOString(), m.v],
            unit: unitDef,
            machineId,
            sensorId: key,
            time: m.t
          })) || [];
        setSeries(prevSeries => {
          const updatedSeries = prevSeries.map(s => {
            if (s.sensorId === key) {
              return { ...s, data: newValue };
            }
            return s;
          });
          return updatedSeries;
        });
      });
      let yAxisIndex = 0;
      setYAxis(prevYAxis =>
        prevYAxis.map(y => {
          const willShow = sensorHistoryMap.has(y.sensorId);
          const ny = {
            ...y,
            show: willShow,
            position: determineDirection(yAxisIndex),
            offset: calculateValue(yAxisIndex)
          };
          if (willShow) yAxisIndex += 1;
          return ny;
        })
      );
    }
  }, [sensorHistoryMap, setSeries]);

  useEffect(() => {
    dispatch(loadStatusHistory(loadStatusParam));
  }, [dispatch, loadStatusHistory, loadStatusParam]);

  useEffect(() => {
    if (isSuccess(loadStatusHistoryStatus.status)) {
      setStatusHistoryList(statusHistoryResponse);
    }
  }, [loadStatusHistoryStatus, statusHistoryResponse]);

  useEffect(() => {
    if (sensorHistoryMap.size && statusHistoryList.length && yAxis.length && series.length) {
      const selectedRecord = findSmallestInitialIntervalRecord(sensorHistoryMap);
      // Desired height of the MarkArea in pixels
      const desiredPixelHeight = (chartPixelHeightFromTop * 100) / chartPixelHeightContainer;
      const statusHeight = 3;
      const warningHeight = 1.5;

      const markAreaData = [];
      if (Array.isArray(selectedRecord) && selectedRecord.length > 0) {
        selectedRecord.forEach((sensor, index) => {
          const closest = findClosestStatusDate(sensor.t, statusHistoryList);
          const item = {
            t: sensor.t,
            v: closest.v,
            w: closest.w
          };
          const nextItem = index < selectedRecord.length - 1 ? selectedRecord[index + 1] : item;
          const currentDate = new Date(item.t);
          const strCurrentDate = currentDate.toISOString();
          const nextDate = new Date(nextItem.t);
          const strNextDate = nextDate.toISOString();

          // Main status area
          markAreaData.push([
            {
              y: `${desiredPixelHeight - statusHeight}%`,
              xAxis: strCurrentDate,
              itemStyle: { color: statusMap[item.v] }
            },
            {
              y: `${desiredPixelHeight}%`,
              xAxis: strNextDate
            }
          ]);

          // Critical alert area
          markAreaData.push([
            {
              // need to subtract the status height with warning height
              y: `${desiredPixelHeight - statusHeight - warningHeight}%`, // Bottom of the chart
              xAxis: strCurrentDate,
              itemStyle: { color: item.w ? '#F8CC84' : 'white' }
            },
            {
              // need to subtract the status height
              y: `${desiredPixelHeight - statusHeight}%`,
              xAxis: strNextDate
            }
          ]);
        });
      }
      const markArea = {
        silent: true,
        itemStyle: {
          opacity: checkedShowMachineStatus ? 0.9 : 0
        },
        data: markAreaData
      };
      setSeries(prevSeries => updateSeriesWithMarkArea(prevSeries, markArea));
      setTimeout(() => {
        setLoading(false);
      }, '2000');
    } else if (!sensorHistoryMap.size) {
      setLoading(false);
    }
  }, [sensorHistoryMap, statusHistoryList]);

  // need to make this to unable zoom out of range %0 to %100
  const validateZoomRange = (start, end) => ({
    start: Math.max(0, Math.min(start, 100)),
    end: Math.max(0, Math.min(end, 100))
  });

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const chartInstance = chartRef?.current?.getEchartsInstance();
    if (chartInstance) {
      // override of zoom method to avoid zoom out of range
      const handleZoom = params => {
        const zoom = params.batch ? params.batch[0] : params;
        setDataZoomRange(validateZoomRange(zoom.start, zoom.end));
      };
      chartInstance.on('dataZoom', handleZoom);

      // debounce function for resizing the chart to avoid frequent updates
      const handleResize = debounce(() => {
        if (chartRef.current) {
          chartRef.current.getEchartsInstance().resize();
        }
      }, 200);
      window.addEventListener('resize', handleResize);

      return () => {
        chartInstance.off('dataZoom', handleZoom);
        window.removeEventListener('resize', handleResize);
      };
    }
    return () => undefined;
  }, []);

  function getLeftPadding() {
    return sideSpace(sensorHistoryMap?.size)[0];
  }

  function getRightPadding() {
    return sideSpace(sensorHistoryMap?.size)[1];
  }

  const options = useMemo(
    () => ({
      textStyle: {
        fontFamily: 'Roboto, sans-serif'
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(43, 47, 66, 0.96)',
        textStyle: {
          color: '#ffffff',
          fontFamily: 'Roboto, sans-serif'
        },
        confine: true, // Ensure tooltip stays within chart area
        position(point, _params, _dom, _rect, size) {
          // Point is the mouse position, size is the size of the tooltip
          const [x, y] = point;
          const { contentSize } = size;
          const posX = x + contentSize[0] > size.viewSize[0] - 42 ? x - contentSize[0] - 42 : x + 42;
          const posY = y + contentSize[1] > size.viewSize[1] ? y - contentSize[1] : y;
          return [posX, posY];
        },
        formatter(params) {
          let result = '';
          const currentTooltipTime = params[0].data.time;
          if (statusHistoryList.length) {
            const status = findClosestStatusDate(currentTooltipTime, statusHistoryList);
            if (status?.v) {
              const hasWarning = status.w;
              const statusDetail = getMachineStatusProps(status.v);
              const statusWarning = t('enum.machine_status.WARNING').toUpperCase();
              const statusDesc = t(`enum.machine_status.${statusDetail.value}`).toUpperCase();
              const ColorSquare = `
                <span
                  style="
                    display:inline-block;
                    margin-right:5px;
                    width:10px;
                    height:10px;
                    background-color:${statusDetail.color};
                  "
                ></span>`;
              const Chip = hasWarning
                ? `
                  <div
                    style="
                      background-color: #ffcc00;
                      color: white;
                      padding: 3px 6px;
                      border-radius: 16px;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 12px;
                    "
                  >
                    ${statusWarning}
                  </div>
                `
                : '';
              result += `${ColorSquare}${statusDesc}${Chip}<br/><br/>`;
            }
          }

          // Extract all sensorIds from params
          const sensorIdsInParams = new Set(params.map(p => p.data.sensorId));

          // Filter the map to exclude entries with sensorIds in params
          const filteredSensorHistory = [...sensorHistoryMap].filter(([sensorId]) => !sensorIdsInParams.has(sensorId));

          // Find the closest time for each filtered entry
          const nearestSensors = filteredSensorHistory.map(([sensorId, historyList]) => {
            // Find the entry with the closest time to targetTime
            const closestEntry = historyList.reduce((prev, curr) =>
              Math.abs(curr.t - currentTooltipTime) < Math.abs(prev.t - currentTooltipTime) ? curr : prev
            );
            return {
              sensorId,
              closestEntry
            };
          });

          // Iterate over params and find the sensor details
          const paramAttributes = params.map(param => ({
            name: param.seriesName,
            unit: param.data.unit,
            color: param.color,
            value: param.value[1]
          }));

          // Iterate over graphTemplate sensors list to get sensor details
          const nearestSensorsAttribute = findSensorAttributes(nearestSensors, graphTemplate);

          // Combine sensors in params and nearest sensors
          const combinedList = paramAttributes.concat(nearestSensorsAttribute);

          // Sort sensorAttributes alphabetically by 'name'
          combinedList.sort((a, b) => a.name.localeCompare(b.name));

          const solidLine = '<div style="border-top: 2px solid #FFFFFF;margin: 6px 0;"></div>';
          result += combinedList
            .map(
              (sensor, i) => `
                ${i > 0 ? solidLine : ''}
                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;
                height:10px;background-color:${sensor.color};"></span>${sensor.name}<br/>
                <span style="font-size:16px;font-weight:bold;margin: 6px 0;">${sensor.value} ${sensor.unit}</span>`
            )
            .join('<br/>');
          if (combinedList.length > 0) result += solidLine;
          result += `<p style="margin: 3px 0;"> ${getFormattedDate(currentTooltipTime)}</p>`;
          return result;
        }
      },
      grid: {
        top: '80px',
        left: getLeftPadding(),
        right: getRightPadding(),
        height: `${chartPixelHeight}px`
      },
      xAxis: xAxisDefaultValue,
      yAxis: yAxis.length > 0 ? yAxis : yAxisDefaultValue,
      dataZoom: [
        {
          type: 'slider',
          start: dataZoomRange.start,
          end: dataZoomRange.end,
          labelFormatter(value) {
            return `${getFormattedDate(value)}   `;
          }
        }
      ],
      series: series.length > 0 ? series : seriesDefaultValue
    }),
    [yAxis, series, statusHistoryList, sensorHistoryMap]
  );

  const onChangeInterval = event => {
    const intervalParam = event.target.value;
    if (intervalParam !== PERIOD_TYPE_CUSTOM_RANG) setInterval(intervalParam);
  };

  const handleOpenCustomDateDialog = () => {
    setOpenCustomDateDialog(true);
  };

  const handleCloseCustomDateDialog = () => {
    setOpenCustomDateDialog(false);
  };

  const handleSetValuesCustomPeriod = (customPeriodStartParam, customPeriodEndParam) => {
    setInterval(PERIOD_TYPE_CUSTOM_RANG);
    setCustomPeriodStart(customPeriodStartParam);
    setCustomPeriodEnd(customPeriodEndParam);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleCheckboxChange = event => {
    setSeries(prevSeries => setMarkAreaVisibility(prevSeries, event.target.checked));
    setCheckedShowMachineStatus(event.target.checked);
  };

  const handleRefreshInfo = () => {
    dispatch(getGraphsTemplateById(templateId));
    setForceReload(!forceReload);
  };

  const toggleSeriesVisibility = (prevSeries, index) =>
    prevSeries.map((s, i) => {
      if (i === index) {
        const show = !s.show;
        return {
          ...s,
          show,
          itemStyle: { opacity: show ? 1 : 0 },
          lineStyle: { ...s.lineStyle, opacity: show ? 1 : 0 }
        };
      }
      return s;
    });

  const toggleLineVisibility = index => {
    setSensorVisibilityList(sensorVisibilityList.map((s, i) => (i === index ? !s : s)));
    setSeries(prevSeries => toggleSeriesVisibility(prevSeries, index));
  };

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        width: '100%',
        p: { xs: 1, sm: 2, md: 2, lg: 3 }
      }}
      data-selector="chart-template-details"
    >
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} sx={{ order: { xs: 1, sm: 1, md: 1, lg: 1 } }}>
          <FormControl variant="filled" sx={{ minWidth: { xs: '100%', sm: '100%', md: '305px' } }}>
            <InputLabel id="template-label">{t('chart_template_details.filter_time')}</InputLabel>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              labelId="period-type-label"
              id="period-type-select"
              onChange={onChangeInterval}
              value={interval}
            >
              {periodTypeList.map(periodType => (
                <MenuItem key={periodType} value={periodType}>
                  {t(`enum.chart_templates.period.${periodType}`)}
                </MenuItem>
              ))}
              <MenuItem
                key={`${PERIOD_TYPE_CUSTOM_RANG}`}
                value={`${PERIOD_TYPE_CUSTOM_RANG}`}
                onClick={handleOpenCustomDateDialog}
              >
                {t('enum.chart_templates.period.PERIOD_TYPE_CUSTOM_RANG')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!isDownMd && (
          <Grid item md={2} lg="auto" sx={{ order: { md: 3, lg: 2 } }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  arrow
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Box>
                      {MACHINE_STATUS_SIMPLE_MAP.map(status => (
                        <Box
                          key={status.value}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1
                          }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              backgroundColor: status.color,
                              marginRight: 1
                            }}
                          />
                          <Typography variant="body2">{t(`enum.machine_status.${status.value}`)}</Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                >
                  <Button
                    sx={{
                      textTransform: 'none'
                    }}
                    startIcon={<InfoOutlinedIcon />}
                    onClick={handleTooltipOpen}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#000000'
                      }}
                    >
                      {t('chart_template_details.legend')}
                    </Typography>
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={10} lg="auto" sx={{ order: { xs: 3, sm: 3, md: 4, lg: 3 } }}>
          <FormControlLabel
            control={<Checkbox checked={checkedShowMachineStatus} onChange={handleCheckboxChange} />}
            label={t('chart_template_details.show_machine_status')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg="auto"
          sx={{
            order: { xs: 2, sm: 2, md: 2, lg: 4 },
            display: 'flex',
            justifyContent: { sm: 'flex-start', md: 'flex-end' },
            marginTop: { xs: 2, sm: 2, md: 0, lg: 0 }
          }}
        >
          <Button startIcon={<RefreshOutlinedIcon />} onClick={handleRefreshInfo}>
            <Typography
              sx={{
                fontSize: '14px'
              }}
            >
              {t('chart_template_details.refresh_info')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={4}>
        {isDownSm ? (
          <Stack direction="row" spacing={2} sx={{ pt: 4 }}>
            <ScreenWarning />
          </Stack>
        ) : (
          <div style={{ position: 'relative' }} id="graph-template">
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
            <ReactEcharts
              ref={chartRef}
              data-selector="chart-template-canvas"
              option={options}
              opts={{ renderer: 'canvas' }}
              notMerge
              lazyUpdate
              style={{
                minHeight: `${chartPixelHeightContainer}px`,
                position: 'relative',
                overflow: 'hidden'
              }}
            />
          </div>
        )}
        {!!resumedSensorMap?.size && !!sensorAverageMap?.size && (
          <ChartTemplateFooterContainer
            footerRef={footerRef}
            resumedSensorMap={resumedSensorMap}
            sensorVisibilityList={sensorVisibilityList}
            sensorAverageMap={sensorAverageMap}
            toggleLineVisibility={toggleLineVisibility}
          />
        )}
      </Stack>
      <CustomDateDialog
        open={openCustomDateDialog}
        handleClose={handleCloseCustomDateDialog}
        title={t('custom_date_dialog.title')}
        subTitle={t('custom_date_dialog.subtitle')}
        startTimeCaption={t('custom_date_dialog.start_time')}
        endTimeCaption={t('custom_date_dialog.end_time')}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('default_actions.apply')}
        setValues={handleSetValuesCustomPeriod}
        maxDays={6}
      />
    </Paper>
  );
}

ChartTemplateDetails.propTypes = {
  graphTemplate: PropTypes.shape(),
  chartRef: PropTypes.shape(),
  footerRef: PropTypes.shape(),
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  customPeriodStart: PropTypes.string.isRequired,
  setCustomPeriodStart: PropTypes.func.isRequired,
  customPeriodEnd: PropTypes.string.isRequired,
  setCustomPeriodEnd: PropTypes.func.isRequired
};

ChartTemplateDetails.defaultProps = {
  graphTemplate: undefined,
  chartRef: null,
  footerRef: null
};
