import axios from './axios';

const cleanparams = obj =>
  Object.entries(obj)
    .filter(([, v]) => v != null && v !== '')
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const loadGraphTemplates = (machineId, accessType, sensor, templateName) => {
  const params = {
    access_type: accessType || undefined,
    sensor_name: sensor || undefined,
    template_name: templateName || undefined
  };

  return axios({
    method: 'get',
    url: `/v2/machine/${machineId}/trends/chart-templates`,
    params
  });
};

export const favoriteGraphsTemplate = graphId =>
  axios({
    method: 'post',
    url: `/v2/trend/chart-templates/${graphId}/favorite`,
    data: {
      chart_template_id: graphId
    }
  });

export const loadMachineSensorTreeView = (productionLines, sensors, machineIds) => {
  const params = cleanparams({
    production_lines: productionLines,
    sensors,
    machine_ids: machineIds
  });

  return axios({
    method: 'get',
    url: `/v2/filters/machines/production-lines/tree-view`,
    params
  });
};

export const createGraphTemplate = values =>
  axios({
    method: 'post',
    url: `/v2/trends/chart-templates`,
    data: values
  });

export const getGraphTemplateById = graphId =>
  axios({
    method: 'get',
    url: `/v2/trend/chart-templates/${graphId}`
  });

export const delGraphTemplateById = templateID =>
  axios({
    method: 'delete',
    url: `/v2/trend/chart-templates/${templateID}`
  });

export const loadSensorHistory = (machineId, params) =>
  axios({
    method: 'get',
    url: `/v2/machine/${machineId}/metrics/sensor-history`,
    params
  });

export const loadStatusHistory = (machineId, params) =>
  axios({
    method: 'get',
    url: `/v2/machine/${machineId}/metrics/status-history`,
    params
  });

export const loadSensorAverage = (machineId, params) =>
  axios({
    method: 'get',
    url: `/v2/machine/${machineId}/metrics/sensor-averages`,
    params
  });
export const deleteSensorGraphTemplate = values =>
  axios({
    method: 'put',
    url: `/v2/trends/chart-templates`,
    data: values
  });

export const updateGraphTemplate = values =>
  axios({
    method: 'put',
    url: `/v2/trends/chart-templates`,
    data: values
  });

export const exportGraphTemplate = (templateID, values) =>
  axios({
    method: 'post',
    url: `/v2/trend/chart-templates/export/${templateID}`,
    data: values
  });
