/* eslint-disable no-param-reassign */
import { eventChannel } from 'redux-saga';
import { getWebsocketToken } from '../../api/stream';
import retryOnError from './retry';
import * as features from '../../helpers/features';

function consoleLog(...args) {
  if (features.get('is_debug')) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

const openStreams = {};

const urlWithToken = (url, token) => {
  if (url.includes('?')) {
    return `${url}&token=${token}`;
  }

  return `${url}?token=${token}`;
};

export function cleanupOpenWebscoketConnections() {
  Object.keys(openStreams).forEach(endpoint => {
    const stream = openStreams[endpoint];

    if (stream.socket) {
      stream.socket.close();
    }
    if (stream.channel) {
      stream.channel.close();
    }

    delete openStreams[endpoint];
  });
}

async function reloadWebsocketToken() {
  if (localStorage.getItem('loadingWebsocketTokenLock')) {
    // prevent from requesting several new tokens simultaneously
    return;
  }

  localStorage.setItem('loadingWebsocketTokenLock', true);
  const { data } = await getWebsocketToken();
  localStorage.setItem('websocketToken', data.token);
  localStorage.setItem('loadingWebsocketTokenLock', false);
}

export async function createWebSocketConnection(url) {
  const token = localStorage.getItem('websocketToken');

  return new Promise((resolve, reject) => {
    const socket = new WebSocket(urlWithToken(url, token));

    const onOpen = () => resolve(socket);
    const onClose = event => {
      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
      if (event.code > 1005) {
        reject(event);
      }
    };

    socket.addEventListener('open', onOpen);
    socket.addEventListener('close', onClose);
  });
}

async function createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage) {
  openStreams[endpoint].socket = await retryOnError(createWebSocketConnection, endpoint);

  if (openStreams[endpoint].socket) {
    consoleLog(`%c--> WebSocket connection for ${endpoint}: `, 'color: blue;', `is opened`);
    openStreams[endpoint].socket.addEventListener('message', onMessage);
    openStreams[endpoint].socket.addEventListener('close', async event => {
      consoleLog(`--> WebSocket connection for ${endpoint} is closed with event code: ${event.code}`);
      if (event.code > 1005) {
        await reloadWebsocketToken();
        createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage);
      }
    });
  }
}

export async function createSocketChannel(endpoint) {
  if (openStreams[endpoint]) {
    return openStreams[endpoint].channel;
  }

  openStreams[endpoint] = {};
  openStreams[endpoint].channel = eventChannel(emit => {
    const onMessage = event => emit(event.data);

    createWebSocketConnectionAndRecreateOnUnexpectedClose(endpoint, onMessage);

    return () => {
      if (openStreams[endpoint].socket) {
        openStreams[endpoint].socket.removeEventListener('message', onMessage);
      }
    };
  });

  return openStreams[endpoint].channel;
}
