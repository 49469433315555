import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateExportDialog from 'components/DateExportDialog';
import { getDateRange, PERIOD_TYPE_CUSTOM_RANG } from 'attrs/chartsTemplate';
import { convertChartTemplate2Bas64, generateImage } from 'helpers/chartUtils';
import { StyledChip } from './elements';
import ChartTemplateDetails from './ChartTemplateDetails';
import { exportGraphsTemplate, getGraphsTemplateById } from '../../../../../redux/graphTemplates/actions';
import { getCurrentTemplate } from '../../../../../redux/graphTemplates/selectors';
import { DeleteTemplate } from './DeleteTemplate';
import { getUserLocale } from '../../../../../helpers/utils';

export function ContainerChartTemplateDetails() {
  const dispatch = useDispatch();

  const { id, templateId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const graphTemplate = useSelector(getCurrentTemplate);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [interval, setInterval] = useState('');
  const [customPeriodStart, setCustomPeriodStart] = useState();
  const [customPeriodEnd, setCustomPeriodEnd] = useState();
  const chartRef = useRef(null);

  const isDataMachineExport = false;

  const footerRef = useRef(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    history.push(`/dashboard/${id}/graph-templates/${templateId}/edit`);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteTemplateDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleExportdata = () => {
    setOpenExportDialog(true);
  };

  const handleCloseExportDataDialog = () => {
    setOpenExportDialog(false);
  };

  useEffect(() => {
    dispatch(getGraphsTemplateById(templateId));
  }, [dispatch, getGraphsTemplateById, templateId]);

  const normalizeValuesForActions = async values => {
    const { action } = values;
    const updateValue = values;

    if (action === 'csv' || action === 'excel') {
      updateValue.graphs = null;
      return updateValue;
    }

    const chartInstance = chartRef?.current?.getEchartsInstance();
    let chartPng = '';
    let footerPng = '';
    if (chartInstance && footerRef) {
      chartPng = convertChartTemplate2Bas64(chartInstance, 1024, 580);
      footerPng = await generateImage(footerRef);
    }

    updateValue.graphs = [
      {
        name: graphTemplate?.name,
        image: chartPng
      },
      {
        name: `${graphTemplate?.name} footer`,
        image: footerPng
      }
    ];

    return updateValue;
  };

  const handleSetValuesDateExportPeriod = async (
    _newStartDateTimeText,
    _newFinalDateTimeText,
    fileFormatParam,
    samplePeriodParam,
    timezoneParam
  ) => {
    const [pastDate, currentDate] =
      interval !== PERIOD_TYPE_CUSTOM_RANG ? getDateRange(interval) : [customPeriodStart, customPeriodEnd];
    const values = {
      date_from: pastDate,
      date_to: currentDate,
      window: samplePeriodParam,
      timezone: timezoneParam,
      decimal_separator: ',',
      locale: getUserLocale(),
      action: fileFormatParam
    };

    const normalizedValue = await normalizeValuesForActions(values);

    dispatch(exportGraphsTemplate(templateId, normalizedValue));
  };

  return (
    <>
      <DeleteTemplate
        machineID={id}
        templateID={graphTemplate?.id}
        open={openDeleteDialog}
        handleCloseDialog={handleCloseDeleteTemplateDialog}
      />
      <Container maxWidth={false} data-selector="container-chart-template-details">
        <Box sx={{ mx: '24px', pt: '48px' }}>
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Button
                variant="text"
                startIcon={<ArrowBackIosRoundedIcon />}
                sx={{ fontSize: 14, p: 0, pt: '2px' }}
                onClick={() => history.goBack()}
                data-selector="container-chart-template-details-btn-back"
              >
                {t('default_actions.back')}
              </Button>
              <Divider orientation="vertical" flexItem />
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="primary.main"
                  sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
                  href={`/dashboard/${id}/graph-templates`}
                  data-selector="chart-templates-link"
                >
                  {t('container_chart_template_details.breadcrumb.graphs_templates')}
                </Link>
                <Typography
                  component="span"
                  color="text.primary"
                  sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
                >
                  {graphTemplate?.name}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography component="span" sx={{ fontSize: 20, fontWeight: 'bold' }}>
                    {graphTemplate?.name}
                  </Typography>
                  {graphTemplate?.tmpl_access_type && (
                    <StyledChip
                      label={t(`machines.detail.tabs.templates.tmpl_access_type.${graphTemplate?.tmpl_access_type}`)}
                      size="small"
                      sx={{ backgroundColor: '#DEF1EE', mb: 0 }}
                    />
                  )}
                </Stack>
                <Typography component="span" color="text.secondary" sx={{ fontSize: 14 }}>
                  {graphTemplate?.description}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                sx={{ height: '56px' }}
                onClick={handleMenu}
                data-selector="chart-template-details-btn-options"
              >
                <MoreHorizIcon sx={{ fontSize: '36px !important' }} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{ mt: 1 }}
                PaperProps={{
                  style: {
                    width: 200
                  }
                }}
                data-selector="chart-template-details-menu-options"
              >
                <MenuItem onClick={handleEdit} data-selector="chart-template-details-btn-edit">
                  <ListItemIcon>
                    <ModeEditOutlineOutlinedIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('default_actions.edit')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleExportdata} data-selector="chart-template-details-btn-export-data">
                  <ListItemIcon>
                    <DownloadOutlinedIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('container_chart_template_details.menu_options.export_data')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete} data-selector="chart-template-details-btn-delete">
                  <ListItemIcon>
                    <DeleteForeverOutlinedIcon fontSize="small" sx={{ color: '#F13309' }} />
                  </ListItemIcon>
                  <ListItemText>{t('default_actions.delete')}</ListItemText>
                </MenuItem>
              </Menu>
            </Stack>
            <ChartTemplateDetails
              graphTemplate={graphTemplate}
              chartRef={chartRef}
              footerRef={footerRef}
              interval={interval}
              setInterval={setInterval}
              customPeriodStart={customPeriodStart}
              setCustomPeriodStart={setCustomPeriodStart}
              customPeriodEnd={customPeriodEnd}
              setCustomPeriodEnd={setCustomPeriodEnd}
            />
          </Stack>
        </Box>
      </Container>
      <DateExportDialog
        open={openExportDialog}
        handleClose={handleCloseExportDataDialog}
        title={t('dialog.export_template.title')}
        subTitle={t('dialog.export_template.subtitle')}
        startTimeCaption={t('dialog.data_exports.time_from')}
        endTimeCaption={t('dialog.data_exports.time_to')}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('dialog.data_exports.export')}
        setValues={handleSetValuesDateExportPeriod}
        showDatePicker={false}
        showTimePicker={false}
        isDataMachineExport={isDataMachineExport}
        maxWidth="464px"
      />
    </>
  );
}
