import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Step,
  Stepper
} from '@mui/material';
import { FormElements, NewDialog, NotifyIcon } from 'web-components';
import { getMillSensor } from 'attrs/sensorType';
import { compose } from 'redux';
import { getData } from '../../../../../redux/rootSelectors';
import {
  loadMachines,
  loadMachinesSensorsTreeView,
  loadProductionsLines,
  loadSensors
} from '../../../../../redux/filters/actions';
import {
  getMachineSensorsTreeView,
  getMachinesList,
  getProductionLines,
  getSensors
} from '../../../../../redux/filters/selectors';
import { createGraphsTemplate } from '../../../../../redux/graphTemplates/actions';
import { CustomTreeView } from '../../../../../components/CustomTreeView';
import { CustomStepLabel } from './AddNewTemplate.Styled';
import { periodObject } from '../../../../../helpers/periodObject';
import { generateColor } from '../../../../../helpers/colorUtils';
import { StepIconComponent } from '../StepIconComponent';

const steps = ['Step1', 'Step2'];

export function AddNewTemplate({ machine: machineParam, open, handleCloseDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const productionLinesObj = useSelector(compose(getData, getProductionLines));
  const treeView = useSelector(compose(getData, getMachineSensorsTreeView));
  const sensors = useSelector(compose(getData, getSensors));
  const machines = useSelector(compose(getData, getMachinesList));

  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [templateIdentification, setTemplateIdentification] = useState('');
  const [filterTime, setFilterTime] = useState(periodObject[0].value);
  const [description, setDescription] = useState('');
  const [accessType, setAccessType] = useState('ACCESS_TYPE_USER_ONLY');
  const [clear, setClear] = useState(false);
  const [alertSelectSensors, setAlertSelectSensors] = useState(false);
  const [errors, setErrors] = useState({
    templateIdentification: '',
    description: ''
  });

  const resetAllStates = () => {
    setActiveStep(0);
    setFilteredTreeData(treeView?.production_lines || []);
    setSelectedProductLines([]);
    setSelectedMachines([]);
    setSelectedSensors([]);
    setSelectedItems([]);
    setTemplateIdentification('');
    setFilterTime(periodObject[0].value);
    setDescription('');
    setAccessType('ACCESS_TYPE_USER_ONLY');
    setClear(false);
    setErrors({});
    setAlertSelectSensors(false);
  };

  useEffect(() => {
    if (!open) {
      resetAllStates();
    }
  }, [open]);

  useEffect(() => {
    if (open && !!machineParam?.id && !selectedProductLines?.length && !selectedMachines?.length) {
      const preDefProdLineFilter = [{ title: machineParam.production_line, value: machineParam.production_line }];
      const preDefMachineFilter = [{ id: machineParam.id, title: machineParam.name, value: machineParam.name }];
      setSelectedProductLines(preDefProdLineFilter);
      setSelectedMachines(preDefMachineFilter);
    }
  }, [open, machineParam]);

  useEffect(() => {
    if (selectedProductLines.length || selectedMachines.length || selectedSensors.length) {
      const params = {
        productionLines: selectedProductLines.map(line => line.value),
        sensors: selectedSensors.map(sensor => sensor.value),
        machineIds: selectedMachines.map(machine => machine.id)
      };
      dispatch(loadMachinesSensorsTreeView(params));
      dispatch(loadProductionsLines(params));
      dispatch(loadMachines(params));
    }
  }, [dispatch, selectedProductLines, selectedSensors, selectedMachines]);

  useEffect(() => {
    if (!selectedProductLines.length && !selectedMachines.length) {
      dispatch(loadSensors({ productionLines: [], machineIds: [] }));
    }
  }, [dispatch]);

  useEffect(() => {
    const searchTermsArray = [...selectedProductLines, ...selectedMachines, ...selectedSensors].map(item => item.title);

    const searchTree = (nodes, searchTerms) => {
      if (searchTerms.length === 0) return nodes;

      return nodes.reduce((acc, node) => {
        const isLineMatch = searchTerms.some(term => node.name.toLowerCase() === term.toLowerCase());

        let matchingMachines = [];
        if (node.machines) {
          matchingMachines = node.machines.reduce((machinesAcc, machine) => {
            const isMachineMatch = searchTerms.some(term => machine.name.toLowerCase() === term.toLowerCase());

            let matchingSensors = [];
            if (machine.sensors) {
              matchingSensors = machine.sensors.filter(sensor =>
                searchTerms.some(
                  term =>
                    sensor.custom_sensor_name?.toLowerCase().includes(term.toLowerCase()) ||
                    sensor.type.toLowerCase().includes(term.toLowerCase())
                )
              );
            }

            if (isMachineMatch || matchingSensors.length > 0) {
              machinesAcc.push({
                ...machine,
                sensors: isMachineMatch ? machine.sensors : matchingSensors
              });
            }

            return machinesAcc;
          }, []);
        }

        if (isLineMatch || matchingMachines.length > 0) {
          acc.push({
            ...node,
            machines: isLineMatch ? node.machines : matchingMachines
          });
        }

        return acc;
      }, []);
    };

    const updatedTreeData = searchTree(treeView.production_lines || [], searchTermsArray);
    setFilteredTreeData(updatedTreeData);
  }, [selectedProductLines, selectedMachines, selectedSensors, treeView.production_lines]);

  const handleProductLineChange = (event, value) => {
    setSelectedProductLines(value);
    setErrors({});
  };

  const handleMachineChange = (event, newValue) => {
    setSelectedMachines(newValue);
  };

  const handleSensorChange = (event, newValue) => {
    setSelectedSensors(newValue);
  };

  const handleSelectionChange = items => {
    if (!items || !Array.isArray(items)) return;

    const usedColors = new Set();

    const flattenedChildren = items.flatMap((item, itemIndex) =>
      (item.childrenNames || []).map((child, childIndex) => ({
        ...child,
        machineID: item.machineID,
        productLine: item.productLine,
        uniqueIndex: itemIndex * 100 + childIndex
      }))
    );

    const extractedData = flattenedChildren.map(child => {
      const nextIndex = child.uniqueIndex;
      let color;
      do {
        color = generateColor(nextIndex);
      } while (usedColors.has(color));

      usedColors.add(color);

      return {
        machine_id: child.machineID,
        type: child.type,
        auto_scale: true,
        line_color: generateColor(nextIndex),
        line_style: 'LINE_STYLE_SOLID',
        line_thickness: 1,
        max_scale: child.max_scale || null,
        min_scale: child.min_scale || null,
        is_custom: child.is_custom || false
      };
    });

    setSelectedItems(extractedData);
  };

  const validateFields = () => {
    const validationErrors = {};
    if (!templateIdentification) {
      validationErrors.templateIdentification = t(
        'dialog.add_new_template.validation.template_identification_required'
      );
    }
    if (!description) {
      validationErrors.description = t('dialog.add_new_template.validation.description_required');
    }
    return validationErrors;
  };

  const handleInputChange = (fieldName, value) => {
    if (fieldName === 'templateIdentification') {
      setTemplateIdentification(value);
    } else if (fieldName === 'description') {
      setDescription(value);
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: ''
    }));
  };

  const handleNext = () => {
    if (selectedItems.length < 2) {
      setAlertSelectSensors(true);
    } else {
      setAlertSelectSensors(false);
      setActiveStep(prev => prev + 1);
    }
  };

  const handleBack = () => setActiveStep(prev => prev - 1);

  const handleSave = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const values = {
      favorite: false,
      machine_id: machineParam?.id,
      name: templateIdentification,
      description,
      period_type: filterTime,
      sensors: selectedItems,
      tmpl_access_type: accessType
    };
    dispatch(createGraphsTemplate(values));
    handleCloseDialog();
  };

  const content = (
    <Box sx={{ minWidth: { sm: '320px', md: '600px', lg: '800px' } }}>
      {alertSelectSensors && (
        <Grid item xs={12}>
          <Alert severity="error">{t('dialog.add_new_template.alert_to_select_sensors')}</Alert>
        </Grid>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          m: '42px auto'
        }}
      >
        <Stepper activeStep={activeStep} sx={{ width: '200px' }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel
                active={index === activeStep}
                completed={index < activeStep}
                StepIconComponent={props => StepIconComponent({ ...props, icon: index + 1 })}
              />
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormElements.MultiSelect
                label={t('dialog.add_new_template.filter_by_product_line')}
                name="product_line"
                id="product_line"
                bgcolor="#F7F7FA"
                fullWidth
                value={selectedProductLines}
                setFieldValue={handleProductLineChange}
                options={
                  productionLinesObj.length > 0 && productionLinesObj.map(item => ({ title: item, value: item }))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                data-selector="dialog-add-new-template-filter-product-line-field"
                clear={clear}
                setClear={setClear}
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormElements.MultiSelect
                label={t('dialog.add_new_template.filter_by_machine')}
                name="filter_machine"
                id="filter_machine"
                bgcolor="#F7F7FA"
                fullWidth
                multiple
                value={selectedMachines}
                setFieldValue={handleMachineChange}
                options={
                  machines.length > 0 && machines.map(item => ({ id: item.id, title: item.name, value: item.name }))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                data-selector="dialog-add-new-template-filter-product-line-field"
                clear={clear}
                setClear={setClear}
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormElements.MultiSelect
                label={t('dialog.add_new_template.filter_by_sensor')}
                name="filter_sensor"
                id="filter_sensor"
                bgcolor="#F7F7FA"
                fullWidth
                multiple
                value={selectedSensors}
                setFieldValue={handleSensorChange}
                options={
                  sensors.length > 0 &&
                  sensors.map(sensor => {
                    const millSensor = getMillSensor(sensor?.type);
                    const sensorName = t(millSensor?.name || sensor?.custom_sensor_name);
                    return {
                      title: sensorName,
                      value: sensor.type
                    };
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                data-selector="dialog-add-new-template-filter-product-line-field"
                clear={clear}
                setClear={setClear}
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTreeView
                rawData={filteredTreeData}
                selectedItems={selectedItems}
                onSelectionChange={handleSelectionChange}
              />
            </Grid>
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormElements.TextField
                label={t('dialog.add_new_template.template_identification')}
                size="medium"
                name="template_identification"
                id="template_identification"
                required
                fullWidth
                bgcolor="#F7F7FA"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                onChange={e => {
                  handleInputChange('templateIdentification', e.target.value);
                }}
                value={templateIdentification}
                error={!!errors.templateIdentification}
                helperText={errors.templateIdentification}
                data-selector="batch-note-modal-title-field"
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: '-16px' }}>
                <FormElements.Select
                  name="period_time"
                  id="period_time"
                  required
                  fullWidth
                  value={filterTime}
                  onChange={e => setFilterTime(e.target.value)}
                  label={t('dialog.add_new_template.filter_time')}
                >
                  {periodObject.map(ftime => (
                    <option key={ftime.label} value={ftime.value}>
                      {`${t(ftime.label)}`}
                    </option>
                  ))}
                </FormElements.Select>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormElements.TextField
                className="subject"
                label={t('dialog.add_new_template.description')}
                id="descrption"
                required
                margin="normal"
                name="note"
                multiline
                rows={6}
                fullWidth
                onChange={e => {
                  handleInputChange('description', e.target.value);
                }}
                value={description}
                error={!!errors.description}
                helperText={errors.description}
                data-selector="dialog-add-new-template-desciption-field"
                sx={{
                  mt: { md: 1 },
                  mb: { md: '24px' }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>{t('dialog.add_new_template.your_template')}</FormLabel>
                <RadioGroup
                  id="accessType"
                  defaultValue="ACCESS_TYPE_USER_ONLY"
                  value={accessType}
                  onChange={e => setAccessType(e.target.value)}
                  data-selector="dialog-add-new-template-access_type-field"
                >
                  <FormControlLabel
                    value="ACCESS_TYPE_USER_ONLY"
                    control={<Radio />}
                    label={t('dialog.add_new_template.access_type.only_me')}
                    sx={{ marginBottom: '20px' }}
                  />
                  <FormControlLabel
                    value="ACCESS_TYPE_ALL_ORG"
                    control={<Radio />}
                    label={t('dialog.add_new_template.access_type.all_users')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );

  const actionBar = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Button
          variant="text"
          color="secondary"
          onClick={handleCloseDialog}
          sx={{ height: '56px', width: { sm: '100%', md: '90px' } }}
        >
          {t('default_actions.cancel')}
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
            >
              {t('default_actions.back')}
            </Button>
          </Grid>
          {activeStep === steps.length - 1 ? (
            <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={activeStep === 0}
                onClick={handleSave}
                sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
              >
                {t('default_actions.save')}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ height: '56px', width: { sm: '100%', md: '127px' } }}
                onClick={handleNext}
              >
                {t('default_actions.next')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const handleOk = () => {
    handleCloseDialog();
  };

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.add_new_template.title')}
      subTitle={t('dialog.add_new_template.subtitle')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      paddingXContent={{ sm: '1rem', md: '2rem', lg: '3rem' }}
      actionBar={actionBar}
    />
  );
}

AddNewTemplate.propTypes = {
  open: T.bool.isRequired,
  handleCloseDialog: T.func.isRequired,
  machine: T.shape().isRequired
};
