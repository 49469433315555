import styled from 'styled-components';
import { Link } from '@mui/material';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2b3d;
`;

const FormContainer = styled.div`
  background-color: #3b3c4d;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 1.5rem !important;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
    opacity: 0.8;
  }
`;

export { PageWrapper, FormContainer, StyledLink };
