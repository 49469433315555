import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useResolutionCheck } from 'web-components';

import { CellLabel, CellValue, MobileCellWrapper } from './InfoBlockCell.styled';

const InfoBlockCell = ({ localeKey, value }) => {
  const { isSmallDevice } = useResolutionCheck();
  const { t } = useTranslation();

  return (
    <MobileCellWrapper>
      <CellLabel isSmallDevice={isSmallDevice}>{t(`machines.detail.${localeKey}`)}</CellLabel>
      <CellValue isSmallDevice={isSmallDevice}>{value}</CellValue>
    </MobileCellWrapper>
  );
};

InfoBlockCell.propTypes = {
  localeKey: T.string.isRequired,
  value: T.oneOfType([T.string, T.node]).isRequired
};

export default InfoBlockCell;
