import React, { useCallback, useEffect, useState } from 'react';
import { FormElements, NewDialog, NotifyIcon } from 'web-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputAdornment } from '@mui/material';
import { generateColor } from 'helpers/colorUtils';
import { getMillSensor } from 'attrs/sensorType';
import { useParams } from 'react-router-dom';
import { loadMachine } from 'redux/machines/actions';
import { getSelectedMachine } from 'redux/machines/selectors';
import { getData } from '../../../../../redux/rootSelectors';
import {
  loadMachines,
  loadMachinesSensorsTreeView,
  loadProductionsLines,
  loadSensors
} from '../../../../../redux/filters/actions';
import {
  getMachineSensorsTreeView,
  getMachinesList,
  getProductionLines,
  getSensors
} from '../../../../../redux/filters/selectors';

import { CustomTreeView } from '../../../../../components/CustomTreeView';
import { isNullUndefinedOrEmpty } from '../../../../../helpers/utils';

function AddSensorCard({ open, editingSensors, sensorsLength, handleCloseDialog, handleApply }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const treeView = useSelector(compose(getData, getMachineSensorsTreeView));
  const [filteredTreeData, setFilteredTreeData] = useState(() =>
    treeView && treeView.production_lines ? treeView.production_lines : []
  );

  const productionLinesObj = useSelector(compose(getData, getProductionLines));
  const [productionLineList, setProductionLineList] = useState([]);

  const machines = useSelector(compose(getData, getMachinesList));
  const [machineList, setMachineList] = useState([]);

  const sensors = useSelector(compose(getData, getSensors));
  const [sensorList, setSensorList] = useState([]);

  const [selectedProductLines, setSelectedProductLines] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clear, setClear] = useState(false);

  const { id } = useParams();
  const { data: parentMachine } = useSelector(getSelectedMachine);

  useEffect(() => {
    if (!parentMachine?.id) {
      dispatch(loadMachine(id));
    } else {
      const preDefProdLineFilter = [{ title: parentMachine.production_line, value: parentMachine.production_line }];
      const preDefMachineFilter = [{ id: parentMachine.id, title: parentMachine.name, value: parentMachine.name }];
      setSelectedProductLines(preDefProdLineFilter);
      setSelectedMachines(preDefMachineFilter);
    }
  }, [parentMachine, dispatch, loadMachine, id]);

  useEffect(() => {
    if (productionLinesObj && productionLinesObj?.length > 0) {
      const newProductionLines = productionLinesObj?.map(item => ({ title: item, value: item })) || [];
      setProductionLineList(newProductionLines.filter(npl => !isNullUndefinedOrEmpty(npl.title)));
    }
  }, [productionLinesObj]);

  useEffect(() => {
    if (machines && machines?.length > 0) {
      const newMachines = machines?.map(item => ({ id: item.id, title: item.name, value: item.name })) || [];
      setMachineList(newMachines.filter(nm => !isNullUndefinedOrEmpty(nm.title)));
    }
  }, [machines]);

  useEffect(() => {
    if (sensors && sensors?.length > 0) {
      const newSensors =
        sensors?.map(item => {
          const millSensor = getMillSensor(item?.type);
          const sensorName = t(millSensor?.name || item?.custom_sensor_name);
          return { title: sensorName, value: item?.type, type: item?.type };
        }) || [];
      setSensorList(newSensors.filter(ns => !isNullUndefinedOrEmpty(ns.title)));
    }
  }, [sensors]);

  useEffect(() => {
    const params = {
      productionLines: selectedProductLines?.map(item => item.value) || [],
      sensors: selectedSensors?.map(item => item.type) || [],
      machineIds: selectedMachines?.map(item => item.id) || []
    };
    dispatch(loadMachinesSensorsTreeView(params));
  }, [dispatch, selectedProductLines, selectedMachines, selectedSensors]);

  useEffect(() => {
    const params = {
      productionLines: [],
      sensors: [],
      machineIds: []
    };
    dispatch(loadMachinesSensorsTreeView(params));
  }, [dispatch]);

  useEffect(() => {
    const params = {
      productionLines: [],
      machineIds: []
    };
    dispatch(loadProductionsLines(params));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadMachines());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      productionLines: [],
      machineIds: []
    };
    dispatch(loadSensors(params));
  }, [dispatch]);

  const searchTree = useCallback((nodes, searchTerms) => {
    if (!searchTerms.trim()) return nodes;

    const terms = searchTerms
      .toLowerCase()
      .split(' ')
      .filter(term => term);

    return nodes.reduce((acc, node) => {
      const isMatch = terms.some(term => node.name.toLowerCase().includes(term));
      const matchingChildren = node.children ? searchTree(node.children, searchTerms) : [];

      const hasMatchingMachines =
        node.machines && node.machines.some(machine => terms.some(term => machine.name.toLowerCase().includes(term)));
      const hasMatchingSensors =
        node.sensors &&
        node.sensors.some(sensor => terms.some(term => sensor.custom_sensor_name.toLowerCase().includes(term)));

      if (isMatch || matchingChildren.length > 0 || hasMatchingMachines || hasMatchingSensors) {
        const newNode = { ...node, children: matchingChildren };
        acc.push(newNode);
      }
      return acc;
    }, []);
  }, []);

  useEffect(() => {
    const searchTerms = [...selectedProductLines, ...selectedMachines, ...selectedSensors]
      .map(item => item.title)
      .join(' ');

    const updatedTreeData = searchTree(treeView.production_lines || [], searchTerms);
    setFilteredTreeData(updatedTreeData);
  }, [selectedProductLines, selectedMachines, selectedSensors, treeView.production_lines, searchTree]);

  const handleProductLineChange = (_event, value) => {
    setSelectedProductLines(value);
  };

  const handleMachineChange = (_event, newValue) => {
    setSelectedMachines(newValue);
  };

  const handleSensorChange = (_event, newValue) => {
    setSelectedSensors(newValue);
  };

  const handleSelectionChange = items => {
    const flattenedChildren = items.flatMap((item, itemIndex) =>
      item.childrenNames.map((child, childIndex) => ({
        ...child,
        machineId: item.machineID,
        productLine: item.productLine,
        uniqueIndex: itemIndex * 100 + childIndex,
        machine: item.machine
      }))
    );

    const extractedData = flattenedChildren.map((child, idx) => {
      const baseObject = {
        temp_id: sensorsLength + idx + 1,
        type: child.type,
        name: child.name,
        production_line: child.productLine,
        machine_id: child.machineId,
        machine_name: child.machine,
        is_custom: child.is_custom,
        custom_icon_type: child.icon,
        line_color: generateColor(sensorsLength + idx + 1),
        line_style: 'LINE_STYLE_SOLID',
        line_thickness: 1,
        auto_scale: true,
        max_scale: null,
        min_scale: null
      };

      return baseObject;
    });

    setSelectedItems(extractedData);
  };

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_product_line')}
            name="product_line"
            id="product_line"
            bgcolor="#F7F7FA"
            fullWidth
            value={selectedProductLines}
            setFieldValue={handleProductLineChange}
            options={productionLineList}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_machine')}
            name="filter_machine"
            id="filter_machine"
            bgcolor="#F7F7FA"
            fullWidth
            multiple
            value={selectedMachines}
            setFieldValue={handleMachineChange}
            options={machineList}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormElements.MultiSelect
            label={t('dialog.add_new_template.filter_by_sensor')}
            name="filter_sensor"
            id="filter_sensor"
            bgcolor="#F7F7FA"
            fullWidth
            multiple
            value={selectedSensors}
            setFieldValue={handleSensorChange}
            options={sensorList}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                  <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                </InputAdornment>
              )
            }}
            data-selector="dialog-add-new-template-filter-product-line-field"
            clear={clear}
            setClear={setClear}
            sx={{
              mt: { md: 1 },
              mb: { md: 1 }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTreeView
            rawData={filteredTreeData}
            editingSensors={editingSensors}
            onSelectionChange={handleSelectionChange}
            sensorsLength={sensorsLength}
          />
        </Grid>
      </Grid>
    </>
  );

  const handleOk = () => {
    handleApply(selectedItems);
    handleCloseDialog();
  };

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.add_sensor.title')}
      subTitle={t('dialog.add_sensor.subtitle')}
      content={content}
      cancelCaption={t('default_actions.cancel')}
      okCaption={t('default_actions.apply')}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      minWidth="710px"
      maxWidth="98%"
      maxHeight="98%"
    />
  );
}

AddSensorCard.propTypes = {
  open: PropTypes.bool.isRequired,
  editingSensors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sensorsLength: PropTypes.number.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired
};

export default AddSensorCard;
