import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid } from '@mui/material';
import {
  BeadMaterialType,
  FormElements,
  GrindingTechnology,
  InnerLinerMaterial,
  MACHINE_TYPE_NAMES,
  MachineTypes,
  NotifyTypo
} from 'web-components';
import { Formik } from 'formik';
import T from 'prop-types';
import { COLOR_PRIMARY } from 'attrs/colors';
import { MachineSchema } from 'attrs/formValidation';
import {
  getChangeFloatHandler,
  getChangeNumberHandler,
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  getListWithoutDefaultValue,
  getMachineType
} from 'helpers/utils';
import {
  StyledCheckbox,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogSubHeader,
  StyledSambaSystemRadioGroup
} from './EditMachineDialog.Styled';

const EditMachineDialog = ({ machine, isOpen, handleCLose, handleFormSubmit, fullEdit }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const FILTERED_OUT_MACHINE_TYPES = [MACHINE_TYPE_NAMES.NETZSCH_PUMP_GENERIC, MACHINE_TYPE_NAMES.UNKNOWN, null];

  const machineTypes = MachineTypes.filter(({ value }) => !FILTERED_OUT_MACHINE_TYPES.includes(value));

  const handleCheckboxChange = setFieldValue => e => setFieldValue('mechanical_config.samba_system', e.target.checked);

  const handleSelectChange = (setFieldValue, name) => e => setFieldValue(name, e.target.value);

  const optionValueMapper = arr =>
    arr.map(option => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    ));

  return (
    <Formik
      initialValues={machine}
      onSubmit={values => {
        handleFormSubmit(values);
      }}
      validationSchema={MachineSchema}
    >
      {({ values, handleSubmit, handleReset, touched, errors, handleChange, handleBlur, setFieldValue }) => (
        <StyledDialog open={isOpen}>
          <StyledDialogHeader
            theme={theme}
            highlightColor={COLOR_PRIMARY}
            onClose={handleCLose(values, handleReset)}
            narrow
            closeButtonProps={{ color: 'primary' }}
          >
            {t('machines.form.modal_title')}
          </StyledDialogHeader>
          <StyledDialogSubHeader theme={theme} withDivider={false} narrow>
            {t('machines.form.modal_caption')}
          </StyledDialogSubHeader>
          <StyledDialogBody>
            <Grid container>
              <Grid item sm={12} md={6}>
                <Container>
                  {fullEdit && (
                    <>
                      <FormElements.TextField
                        disabled
                        fullWidth
                        id="brand"
                        label={t('machines.form.brand')}
                        margin="normal"
                        name="brand"
                        value={getMachineType(values.type).brand}
                      />
                      <FormElements.TextField
                        disabled
                        fullWidth
                        id="commission_no"
                        label={t('machines.form.commission_number')}
                        margin="normal"
                        name="commission_no"
                        value={values.commission_number}
                      />
                    </>
                  )}
                  <FormElements.TextField
                    fullWidth
                    error={getFormikError({ errors, touched })('name')}
                    helperText={getFormikHelperText({ errors, t })('name')}
                    id="name"
                    label={t('machines.form.name')}
                    placeholder={t('machines.form.name')}
                    margin="normal"
                    name="name"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                  />
                  <FormElements.TextField
                    fullWidth
                    error={getFormikError({ errors, touched })('production_line')}
                    helperText={getFormikHelperText({ errors, t })('production_line')}
                    id="production_line"
                    label={t('machines.form.production_line')}
                    placeholder={t('machines.form.production_line')}
                    margin="normal"
                    name="production_line"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.production_line}
                  />
                  {fullEdit && (
                    <>
                      <FormElements.Select
                        fullWidth
                        error={getFormikError({ errors, touched })('type')}
                        helperText={getFormikHelperText({ errors, t })('type')}
                        handleChange={handleChange}
                        id="type"
                        label={t('machines.form.type')}
                        name="type"
                        onBlur={handleBlur}
                        required
                        value={values.type}
                      >
                        {machineTypes.map(machineType => (
                          <option key={machineType.value} value={machineType.value}>
                            {machineType.type}
                          </option>
                        ))}
                      </FormElements.Select>
                      <StyledSambaSystemRadioGroup
                        theme={theme}
                        disabled={values.type !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}
                      >
                        <NotifyTypo.InfoText>{t('machines.form.samba_system_label')}</NotifyTypo.InfoText>
                        <StyledCheckbox
                          activeColor="primary"
                          checked={values.mechanical_config.samba_system}
                          label={t('machines.form.samba_system_title')}
                          onChange={handleCheckboxChange(setFieldValue)}
                          disabled={values.type !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}
                        />
                      </StyledSambaSystemRadioGroup>
                      <FormElements.Select
                        fullWidth
                        helperText={t('form.helpers.select_option')}
                        handleChange={handleSelectChange(setFieldValue, 'mechanical_config.grinding_technology')}
                        id="grinding_technology"
                        label={t('machines.form.grinding_technology')}
                        name="grinding_technology"
                        onBlur={handleBlur}
                        value={values.mechanical_config.grinding_technology}
                        placeholder={t('form.helpers.select_option')}
                        withEmptyField
                      >
                        {optionValueMapper(getListWithoutDefaultValue(GrindingTechnology))}
                      </FormElements.Select>
                    </>
                  )}
                </Container>
              </Grid>

              {fullEdit && (
                <Grid item sm={12} md={6}>
                  <Container>
                    <FormElements.Select
                      fullWidth
                      helperText={t('form.helpers.select_option')}
                      handleChange={handleSelectChange(setFieldValue, 'mechanical_config.inner_liner_material')}
                      id="inner_liner_material"
                      label={t('machines.form.inner_liner_material')}
                      name="inner_liner_material"
                      onBlur={handleBlur}
                      value={values.mechanical_config.inner_liner_material}
                      withEmptyField
                    >
                      {optionValueMapper(getListWithoutDefaultValue(InnerLinerMaterial))}
                    </FormElements.Select>
                    <FormElements.TextField
                      fullWidth
                      error={getFormikError({ errors, touched })('mechanical_config.sieve_size')}
                      helperText={getFormikHelperText({ errors, t })('mechanical_config.sieve_size')}
                      placeholder={t('form.helpers.input_example_numeric')}
                      id="sieve_size"
                      label={t('machines.form.sieve_size')}
                      margin="normal"
                      name="mechanical_config.sieve_size"
                      type="number"
                      min="0"
                      max="100000"
                      onBlur={handleBlur}
                      onChange={getChangeFloatHandler(setFieldValue)('mechanical_config.sieve_size')}
                      value={getDefaultFieldValue(values.mechanical_config.sieve_size)}
                    />
                    <FormElements.Select
                      fullWidth
                      helperText={t('form.helpers.select_option')}
                      handleChange={handleSelectChange(setFieldValue, 'mechanical_config.bead_material')}
                      id="bead_material"
                      label={t('machines.form.bead_material')}
                      name="bead_material"
                      onBlur={handleBlur}
                      value={values.mechanical_config.bead_material}
                      withEmptyField
                    >
                      {optionValueMapper(getListWithoutDefaultValue(BeadMaterialType))}
                    </FormElements.Select>
                    <FormElements.TextField
                      fullWidth
                      error={getFormikError({ errors, touched })('mechanical_config.beads_size_1')}
                      helperText={getFormikHelperText({ errors, t })('mechanical_config.beads_size_1')}
                      InputLabelProps={{ shrink: true }}
                      placeholder={t('form.helpers.input_example_numeric')}
                      id="bead_size_1"
                      label={t('machines.form.bead_size_1')}
                      margin="normal"
                      name="mechanical_config.bead_size_1"
                      type="number"
                      min="0"
                      max="100000"
                      onBlur={handleBlur}
                      onChange={getChangeFloatHandler(setFieldValue)('mechanical_config.beads_size_1')}
                      value={getDefaultFieldValue(values.mechanical_config.beads_size_1)}
                    />
                    <FormElements.TextField
                      disabled={values.type !== MACHINE_TYPE_NAMES.NETZSCH_MASTER_REFINER}
                      fullWidth
                      error={getFormikError({ errors, touched })('mechanical_config.beads_size_2')}
                      helperText={getFormikHelperText({ errors, t })('mechanical_config.beads_size_2')}
                      InputLabelProps={{ shrink: true }}
                      placeholder={t('form.helpers.input_example_numeric')}
                      id="bead_size_2"
                      label={t('machines.form.bead_size_2')}
                      margin="normal"
                      name="mechanical_config.beads_size_2"
                      type="number"
                      min="0"
                      max="100000"
                      onBlur={handleBlur}
                      onChange={getChangeFloatHandler(setFieldValue)('mechanical_config.beads_size_2')}
                      value={getDefaultFieldValue(values.mechanical_config.beads_size_2)}
                    />
                    <FormElements.TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      placeholder={t('form.helpers.input_example_percentage')}
                      id="bead_filling_level"
                      label={t('machines.form.bead_filling_level')}
                      margin="normal"
                      name="bead_filling_level"
                      type="number"
                      min="0"
                      max="100"
                      onBlur={handleBlur}
                      onChange={getChangeNumberHandler(setFieldValue)('mechanical_config.beads_filling_level')}
                      value={getDefaultFieldValue(values.mechanical_config.beads_filling_level)}
                    />
                  </Container>
                </Grid>
              )}
            </Grid>
          </StyledDialogBody>
          <StyledDialogActions>
            <Button variant="text" color="secondary" onClick={handleCLose(values, handleReset)}>
              {t('dashboard.custom.cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} type="submit">
              {t('dashboard.custom.save')}
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    </Formik>
  );
};

EditMachineDialog.propTypes = {
  machine: T.shape({}),
  isOpen: T.bool.isRequired,
  handleCLose: T.func.isRequired,
  handleFormSubmit: T.func.isRequired,
  fullEdit: T.bool
};

EditMachineDialog.defaultProps = {
  machine: {},
  fullEdit: false
};

export default EditMachineDialog;
