import React, { useRef, useState } from 'react';
import T from 'prop-types';
import { isEqual } from 'lodash';
import { Button, ClickAwayListener, MenuItem, MenuList, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DataTable, NotifyIcon, useResolutionCheck } from 'web-components';

import { generateSortMenuItems } from '../../BatchRecordsOverview.config';
import { Container, PopperStyled } from './SortMenu.styled';

const SortMenu = ({ defaultSort, onSort }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const { isSmallDevice } = useResolutionCheck();
  const sortMenuItems = generateSortMenuItems(t);

  const handleSelectItem =
    ({ colId, order }) =>
    () => {
      onSort(colId, order);
      setIsOpen(false);
    };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const id = isOpen ? 'simple-popover' : undefined;

  return (
    <Container isSmallDevice>
      <Button
        aria-label="select-sort"
        ref={anchorRef}
        color="primary"
        variant="outlined"
        fullWidth
        aria-haspopup="true"
        disableElevation
        onClick={handleOpen}
        aria-describedby={id}
      >
        {t('batch_records.overview.sort_by')}
      </Button>
      <PopperStyled
        id={id}
        className={isSmallDevice ? 'fullWidth' : ''}
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        disablePortal
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={isOpen}>
              {sortMenuItems.map(item => (
                <MenuItem
                  key={item.label}
                  disabled={item.disabled}
                  onClick={item.value && handleSelectItem(item.value)}
                >
                  <NotifyIcon
                    className={isEqual(item.value, defaultSort) ? '' : 'hidden'}
                    fontSize="default"
                    iconName="checkmark"
                  />
                  <span className="menuLabel">{item.label}</span>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </PopperStyled>
    </Container>
  );
};

SortMenu.propTypes = {
  defaultSort: T.shape({
    colId: T.string,
    order: T.oneOf([DataTable.ORDER_ASC, DataTable.ORDER_DESC])
  }),
  onSort: T.func
};

SortMenu.defaultProps = {
  defaultSort: {
    colId: null,
    order: DataTable.ORDER_ASC
  },
  onSort: () => {}
};

export default SortMenu;
