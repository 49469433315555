import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box } from '@mui/material';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import LockResetIcon from '@mui/icons-material/LockReset';

const ScreenWarning = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: '280px',
        margin: '0 auto!important',
        pt: 4,
        textAlign: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {t('general.screen_warning.title')}
      </Typography>
      <Typography sx={{ maxWidth: '300px', color: 'text.secondary' }}>
        {t('general.screen_warning.subtitle')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 144,
          height: 144,
          borderRadius: '50%',
          bgcolor: 'rgba(247, 247, 250, 1)',
          margin: '20px 0 20px 0!important'
        }}
      >
        <ScreenRotationIcon sx={{ fontSize: 64, color: '#000000' }} />
      </Box>
      <Typography
        sx={{ fontSize: 14, color: 'text.secondary', display: 'flex', alignItems: 'left', textAlign: 'left' }}
      >
        <LockResetIcon sx={{ fontSize: 30, mr: 2, color: '#000000' }} />
        {t('general.screen_warning.footer')}
      </Typography>
    </Stack>
  );
};

export default ScreenWarning;
