/* eslint-disable no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { updateAuthTokens } from '../redux/auth/actions';

import { API_URL } from '../attrs/paths';

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

const instance = axios.create({
  baseURL: API_URL
});

const refreshInstance = axios.create({
  baseURL: API_URL
});

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
  // eslint-disable-next-line global-require
  const getStore = require('../redux/store').default;
  const { store } = getStore();

  return refreshInstance
    .get('/v1/tokens', {
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`
      }
    })
    .then(tokenRefreshResponse => {
      store.dispatch(updateAuthTokens(tokenRefreshResponse.data));
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access}`;

      return Promise.resolve();
    })
    .catch(() => {
      store.dispatch(updateAuthTokens(null));
    });
};

instance.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;

  return request;
});

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  statusCodes: [401, 403]
});

export default instance;
