import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Container, Grid, Dialog as MuiDialog } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'web-components';
import { orderBy } from 'lodash';

import { COLOR_PRIMARY } from '../../../../attrs/colors';
import SelectableComparisonSensorCard from '../../../../components/SensorCard/SelectableComparisonSensorCard';
import {
  getSelectedMachineId,
  getSelectedMachineSensorComparisonSensorTypes,
  getSelectedMachineSensors
} from '../../../../redux/machines/selectors';
import { getData } from '../../../../redux/rootSelectors';
import * as E from './elements';

const ComparisonDialog = ({ modify, isMobile, isOpen, showButton, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const machineId = useSelector(getSelectedMachineId);
  const selectedSensorTypes = useSelector(getSelectedMachineSensorComparisonSensorTypes);

  // Get sensors translated & sorted
  const sensors = useSelector(compose(getData, getSelectedMachineSensors));
  const sensorsWithTranslatedNames = (sensors || []).map(item => ({ ...item, name: t(item.name) }));
  const sensorsSortedByName = orderBy(sensorsWithTranslatedNames, item => item.name, 'asc');

  const [open, setOpen] = useState(false);
  const [selectedSensors, setSelected] = useState([]);

  useEffect(() => {
    setSelected(selectedSensorTypes);
  }, [selectedSensorTypes]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClick = (e, type) => {
    if (e) {
      e.stopPropagation();
    }
    if (selectedSensors.includes(type)) {
      setSelected(selectedSensors.filter(sensor => sensor !== type));
    } else {
      setSelected([...selectedSensors, type]);
    }
  };

  const handleCompare = () => {
    const sortedSensors = selectedSensors.sort().join(',');

    const url = `/dashboard/${machineId}/sensor/compare?types=${sortedSensors}`;
    dispatch(push(url));
    setIsOpen();
    setOpen(false);
  };

  return (
    <>
      {showButton && (
        <Button
          variant="outlined"
          fullWidth={isMobile}
          data-selector="machine-compare-sensors-button"
          color={modify ? 'default' : 'primary'}
          style={{ padding: '1rem 1rem' }}
          onClick={() => setOpen(true)}
          size="medium"
          startIcon={<CompareArrowsIcon />}
        >
          {modify ? t('dashboard.sensors.comparison.modify_sensor_selection') : t('dashboard.sensors.compare_action')}
        </Button>
      )}
      <MuiDialog open={open} maxWidth="sm" fullWidth scroll={isMobile ? 'body' : 'paper'}>
        <E.StyledDialogHeader
          highlightColor={COLOR_PRIMARY}
          onClose={() => setOpen(false)}
          closeButtonProps={{ 'data-selector': 'dashboard-sensors-comparison-dialog-close', color: 'primary' }}
        >
          {t('dashboard.sensors.compare_sensors_title')}
        </E.StyledDialogHeader>
        <E.StyledDialogSubHeader withDivider={false}>
          {t('dashboard.sensors.compare_sensors_subtitle')}
        </E.StyledDialogSubHeader>
        <Dialog.Body style={{ padding: '1rem 0 2rem 0' }}>
          <Container maxWidth="md">
            <Grid style={{ padding: '0 2rem 2rem' }} data-selector="sensors-selection-list">
              {sensorsSortedByName.map(sensor => (
                <Grid key={sensor.type} item>
                  {!sensor.is_static ? (
                    <SelectableComparisonSensorCard
                      selected={selectedSensors.includes(sensor.type)}
                      onClick={handleClick}
                      disabled={
                        sensor.is_static || (!selectedSensors.includes(sensor.type) && selectedSensors.length === 6)
                      }
                      sensor={sensor}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              ))}
            </Grid>
          </Container>
        </Dialog.Body>
        <E.StyledDialogActions>
          <Button variant="text" color="secondary" onClick={() => setOpen(false)}>
            {t('dashboard.custom.cancel')}
          </Button>
          <Button
            data-selector="select-sensors-button"
            disabled={selectedSensors.length < 1}
            variant="contained"
            color="primary"
            onClick={handleCompare}
          >
            {t('dashboard.sensors.compare_sensors_compare_button')}
          </Button>
        </E.StyledDialogActions>
      </MuiDialog>
    </>
  );
};

ComparisonDialog.propTypes = {
  modify: T.bool,
  isMobile: T.bool,
  isOpen: T.bool,
  showButton: T.bool,
  setIsOpen: T.func
};

ComparisonDialog.defaultProps = {
  modify: false,
  isMobile: false,
  isOpen: false,
  showButton: true,
  setIsOpen: () => {}
};

export default ComparisonDialog;
