import React from 'react';
import T from 'prop-types';
import MobileCell from './MobileCell';
import { MobileRowWrapper } from './MobileView.styled';
import { BatchRecordsColumnProps, BatchRecordsRowProps } from '../BatchRecordsOverview.config';

const MobileRow = ({ columns, row, onClick }) => {
  const sortColumns = columns.filter((e, i) => i % 2 === 0).concat(columns.filter((e, i) => i % 2));
  return (
    <MobileRowWrapper container spacing={2} onClick={() => onClick(row)}>
      {sortColumns.map(column => (
        <MobileCell
          key={`mobile-row-${row.batchId}-cell-${column.id}`}
          id={column.id}
          label={column.label}
          value={row[column.id]}
        />
      ))}
    </MobileRowWrapper>
  );
};

MobileRow.propTypes = {
  columns: BatchRecordsColumnProps.isRequired,
  row: BatchRecordsRowProps.isRequired,
  onClick: T.func
};

MobileRow.defaultProps = {
  onClick: () => {}
};

export default MobileRow;
