import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ResponsiveIconButton } from 'web-components';
import { HeadingWrapper, NotifyTypoCaption, StyledDivider, StyledPaper, StyledTitle } from './InfoBlock.styled';
import InfoBlockRow from './InfoBlockRow';
import MachineTrackStatusIcon from '../../../MachineTrackStatusIcon/MachineTrackStatusIcon';

const InfoBlock = ({ config, onClickEdit, status }) => {
  const { t } = useTranslation();

  return (
    <StyledPaper elevation={3}>
      <HeadingWrapper expanded>
        <StyledTitle>
          <NotifyTypoCaption>{t(`machines.detail.${config.localeKey}`)} </NotifyTypoCaption>
          {status && (
            <div className="statusWrapper">
              <MachineTrackStatusIcon status={status} />
            </div>
          )}
        </StyledTitle>
        <ResponsiveIconButton
          buttonText={t('machines.detail.edit')}
          color="primary"
          iconName="edit"
          variant="text"
          onClick={onClickEdit}
        />
      </HeadingWrapper>
      {config.rows.map((row, ind) => (
        <div key={row.id}>
          <InfoBlockRow localeKey={row.localeKey} dataProvider={row.row} />
          {ind < config.rows.length - 1 && <StyledDivider />}
        </div>
      ))}
    </StyledPaper>
  );
};

InfoBlock.propTypes = {
  config: T.shape({
    localeKey: T.string,
    rows: T.arrayOf(T.shape({}))
  }).isRequired,
  onClickEdit: T.func,
  status: T.string
};

InfoBlock.defaultProps = {
  onClickEdit: () => {},
  status: null
};

export default InfoBlock;
