import { put, takeLatest } from 'redux-saga/effects';

import * as api from '../../api/stream';
import * as constants from './constants';
import * as authConstants from '../auth/constants';
import { actionStatus, statusAction, getError } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { cleanupOpenWebscoketConnections } from './websocket';

function* handleWebsocketsCleanup() {
  cleanupOpenWebscoketConnections();
  yield localStorage.removeItem('websocketToken');
  yield localStorage.removeItem('loadingWebsocketTokenLock');
}

function* handleLoadWebsocketToken() {
  try {
    const { data } = yield api.getWebsocketToken();

    yield localStorage.setItem('websocketToken', data.token);

    yield put(statusAction(constants.LOAD_WEBSOCKET_TOKEN, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_WEBSOCKET_TOKEN, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.stream.${error}`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}
function* handleWebsockets({ value: isAuthenticated }) {
  yield handleWebsocketsCleanup();
  if (isAuthenticated) {
    yield handleLoadWebsocketToken();
  }
}

export function* watchLoadOrders() {
  yield takeLatest(authConstants.SET_AUTHENTICATION, handleWebsockets);
  yield takeLatest(constants.CLEANUP_WEBSOCKETS, handleWebsocketsCleanup);
}
