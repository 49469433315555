import React from 'react';
import T from 'prop-types';
import _, { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Loader } from 'web-components';

import { StyledWarningStatusChip, TooltipElement, TooltipElementBackground, TooltipWrapper } from './elements';
import {
  getMachineStatusProps,
  getNDecimalPlaces,
  getRoundedValue,
  getSensorPropsFromType,
  getSensorValue,
  getSensorValueYAxis,
  getUnit,
  isCustomSensor,
  isNullOrUndefined,
  parseFloatDefault
} from '../../helpers/utils';

// MACHINE STATUS
const CustomMachineTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length > 0 && ((payload[0] || {}).payload || {}).activeStatus && label) {
    const { activeStatus, warning } = payload[0].payload;
    const tooltipIconClor = getMachineStatusProps(activeStatus).color;

    return (
      <TooltipWrapper>
        <div className="statusWrapper">
          <TooltipElement color={tooltipIconClor}>
            <TooltipElementBackground />
          </TooltipElement>
          {t(`machines.status.${activeStatus.toLowerCase()}`)}
          {Boolean(warning) && <StyledWarningStatusChip />}
        </div>
        <div className="sensorTime">{moment(label).format('DD.MM.YYYY, LTS')}</div>
      </TooltipWrapper>
    );
  }

  return null;
};

CustomMachineTooltip.propTypes = {
  label: T.number,
  active: T.bool,
  payload: T.arrayOf(T.shape({ payload: T.shape({ activeStatus: T.string, warning: T.number }) }))
};

CustomMachineTooltip.defaultProps = {
  label: null,
  active: false,
  payload: null
};

/**
 * One sensor graph data CustomTooltip for LineChart from recharts (https://recharts.org/en-US/api/Tooltip)
 * @param active
 * @param payload
 * @param detail
 * @returns {JSX.Element|null}
 * @constructor
 */
const MetricCustomSensorTooltip = ({ active, payload, detail }) => {
  const { t } = useTranslation();

  if (active && payload && payload.length > 0) {
    const { unit } = payload[0];
    const { time, activeStatus, name, warning, displayValue } = payload[0].payload;
    const tooltipIconColor = getMachineStatusProps(activeStatus).color;

    const sensor = payload[0].payload;

    let sensorProps = sensor;
    if (!detail || !detail.is_custom) {
      sensorProps = getSensorPropsFromType(sensor.name);
    }

    return (
      <TooltipWrapper>
        {activeStatus && (
          <div className="statusWrapper">
            <TooltipElement color={tooltipIconColor}>
              <TooltipElementBackground />
            </TooltipElement>
            {t(`machines.status.${activeStatus.toLowerCase()}`)}
            {Boolean(warning) && <StyledWarningStatusChip />}
          </div>
        )}
        <div>
          <span className="sensorName">{t(sensorProps.name) || sensor.name}</span>
          <br />
          <span className="sensorValue">{`${displayValue} ${getUnit(unit, name)}`}</span>
        </div>
        <div className="sensorTime">{moment(time).format('DD.MM.YYYY, LTS')}</div>
      </TooltipWrapper>
    );
  }

  return null;
};

MetricCustomSensorTooltip.propTypes = {
  active: T.bool,
  payload: T.arrayOf(
    T.shape({
      payload: T.shape({
        displayValue: T.oneOfType([T.node, T.string]),
        time: T.number,
        name: T.string,
        activeStatus: T.string,
        warning: T.number,
        is_custom: T.bool,
        value: T.oneOfType([T.string, T.number])
      }),
      unit: T.string
    })
  ),
  detail: T.shape({
    is_custom: T.bool
  })
};

MetricCustomSensorTooltip.defaultProps = {
  active: false,
  payload: null,
  detail: null
};

/**
 * Comparison sensor graph data CustomTooltip for LineChart from recharts (https://recharts.org/en-US/api/Tooltip)
 * @param active
 * @param payload
 * @param detail
 * @returns {JSX.Element|null}
 * @constructor
 */
const MetricsCustomSensorCompareTooltip = ({ active, payload, detail }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length > 0) {
    const { time, activeStatus, warning } = payload[0].payload;
    const tooltipIconColor = getMachineStatusProps(activeStatus).color;

    return (
      <TooltipWrapper>
        {activeStatus && (
          <div className="statusWrapper">
            <TooltipElement color={tooltipIconColor}>
              <TooltipElementBackground />
            </TooltipElement>
            {t(`machines.status.${activeStatus.toLowerCase()}`)}
            {Boolean(warning) && <StyledWarningStatusChip />}
          </div>
        )}
        {payload.map((sensor, key) => {
          let sensorProps = sensor;
          if (!isCustomSensor(sensor.name)) {
            sensorProps = {
              ...sensorProps,
              ...getSensorPropsFromType(sensor.name)
            };
          } else {
            const custom = (detail || []).filter(d => d.type === sensor.name).pop() || {};
            sensorProps = {
              ...custom,
              ...sensorProps,
              unit: sensor.unit.split(';')[0],
              name: sensor.unit.split(';')[1]
            };
          }
          return (
            <div key={sensor.name}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <TooltipElement color={sensor.color} sensorLegend />
                <span className="sensorName">{t(sensorProps.name)}</span>
              </div>
              <span className="sensorValue">{`${getSensorValue(sensorProps, sensor.value)} ${getUnit(
                sensorProps.unit,
                sensorProps.name
              )}`}</span>
              {payload[key + 1] && <hr style={{ margin: '0.75rem 0' }} />}
            </div>
          );
        })}
        <div className="sensorTime">{moment(time).format('DD.MM.YYYY, LTS')}</div>
      </TooltipWrapper>
    );
  }

  return null;
};

MetricsCustomSensorCompareTooltip.propTypes = {
  active: T.bool,
  payload: T.arrayOf(
    T.shape({
      payload: T.shape({
        value: T.oneOfType([T.node, T.string]),
        time: T.number,
        name: T.string,
        activeStatus: T.string,
        warning: T.number
      }),
      unit: T.string
    })
  ),
  detail: T.arrayOf(T.shape({}))
};

MetricsCustomSensorCompareTooltip.defaultProps = {
  active: false,
  payload: null,
  detail: []
};

/**
 * Comparison sensor graph data CustomTooltip for LineChart from recharts (https://recharts.org/en-US/api/Tooltip)
 * @param active
 * @param payload
 * @returns {JSX.Element|null}
 * @constructor
 */
const BatchMetricsCustomSensorCompareTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length > 0) {
    const { time, activeStatus, warning } = payload[0].payload;
    const tooltipIconColor = getMachineStatusProps(activeStatus).color;
    return (
      <TooltipWrapper>
        {activeStatus && (
          <div className="statusWrapper">
            <TooltipElement color={tooltipIconColor}>
              <TooltipElementBackground />
            </TooltipElement>
            {t(`machines.status.${activeStatus.toLowerCase()}`)}
            {Boolean(warning) && <StyledWarningStatusChip />}
          </div>
        )}
        {payload.map((sensor, key) => {
          let sensorProps = sensor;
          if (!isCustomSensor(sensor.dataKey)) {
            sensorProps = getSensorPropsFromType(sensor.dataKey);
          } else {
            sensorProps = {
              ...sensorProps
            };
          }
          return (
            <div key={sensor.name}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <TooltipElement color={sensor.color} sensorLegend />
                <span className="sensorName">{t(sensorProps.name)}</span>
              </div>
              <span className="sensorValue">{`${getSensorValue(sensor, sensor.value)} ${getUnit(
                sensorProps.unit,
                sensorProps.name
              )}`}</span>
              {payload[key + 1] && <hr style={{ margin: '0.75rem 0' }} />}
            </div>
          );
        })}
        <div className="sensorTime">{moment(time).format('DD.MM.YYYY, LTS')}</div>
      </TooltipWrapper>
    );
  }

  return null;
};

BatchMetricsCustomSensorCompareTooltip.propTypes = {
  active: T.bool,
  payload: T.arrayOf(
    T.shape({
      payload: T.shape({
        value: T.oneOfType([T.node, T.string]),
        time: T.number,
        name: T.string,
        activeStatus: T.string,
        warning: T.number,
        is_custom: T.bool
      }),
      unit: T.string
    })
  )
};

BatchMetricsCustomSensorCompareTooltip.defaultProps = {
  active: false,
  payload: null
};

// Shared helpers
const createReferenceChartData = (data, xAxisDomain) => {
  if (!data.length) {
    return [];
  }

  const ordered = orderBy(data, item => item.updated_at, 'asc');
  const chartData = [];

  if (ordered.length === 0 || xAxisDomain[1] !== moment(ordered[0].updated_at).valueOf()) {
    // Add first state with first list value or "null" from xAxis start to first item
    chartData.push({
      start: xAxisDomain[1],
      end: ordered[0] ? moment(ordered[0].updated_at).valueOf() : xAxisDomain[0],
      value: ordered[0] ? ordered[0].value : null,
      warning: ordered[0].warning
    });
  }

  const endValue = xAxisDomain[0] > moment().valueOf() ? moment().valueOf() : xAxisDomain[0];
  ordered.forEach((td, key) => {
    // If there is no entry after the item (key + 1), take last time from xAxis or current date as end
    const returning = [
      {
        start: moment(td.updated_at).valueOf(),
        end: ordered[key + 1] ? moment(ordered[key + 1].updated_at).valueOf() : endValue,
        value: td.value,
        warning: td.warning
      }
    ];

    chartData.push(...returning);
  });

  return chartData;
};

const getMachineStatusByTime = (time, machineStatusData) => {
  let status = null;
  machineStatusData.forEach(machineStatus => {
    if (time >= machineStatus.start && time < machineStatus.end) {
      status = machineStatus.value;
    }
  });

  return status;
};

const getMachineWarningStatusByTime = (time, machineStatusData) => {
  const selectedMachineStatus = machineStatusData.find(({ start, end }) => time >= start && time < end);
  return selectedMachineStatus ? selectedMachineStatus.warning : false;
};

const timeFromNow = (endDate, timeDiff) => endDate - timeDiff;

const getVisualizationValues = ({ yMin, yMax }) => {
  const yMinValue = yMin === 'auto' || !yMin ? 0 : yMin;
  const yMaxValue = yMax === 'auto' || !yMax ? 0 : yMax;

  return [yMinValue, yMaxValue];
};

/**
 * Retrieves visualization values automatically based on provided options.
 *
 * @param {Object} options - The options for retrieving visualization values.
 * @param {number|string} options.yMin - The minimum y-value for the visualization.
 *     If set to 'auto' or not provided, it defaults to 0.
 * @param {number|string} options.yMax - The maximum y-value for the visualization.
 *     If set to 'auto' or not provided, it defaults to 'auto'.
 * @returns {Array} - An array containing the retrieved visualization values.
 */
const getVisualizationValuesAuto = ({ yMin, yMax }) => {
  const yMinValue = yMin === 'auto' || !yMin ? 0 : yMin;
  const yMaxValue = yMax === 'auto' || !yMax ? 'auto' : yMax;

  return [yMinValue, yMaxValue];
};

/**
 * Retrieves the predefined y-axis values for a given sensor.
 *
 * @param {{}} sensor - The sensor object.
 * @returns {Array|null} - The predefined y-axis values for the sensor,
 *  or null if the sensor or visualization is missing.
 */
const getPredefinedYAxis = sensor => {
  if (!sensor || !sensor.visualization) {
    return null;
  }
  return getVisualizationValuesAuto(sensor.visualization);
};

const calcYAxisWidth = (chartData, type, visualization = { yMin: 'auto', yMax: 'auto' }) => {
  const values = chartData.map(i => i[type]);
  const combinedValues = [...getVisualizationValues(visualization), ...values];
  const min = _.min(combinedValues);
  const max = _.max(combinedValues);
  const maxDeviation = parseFloat((max - min).toFixed(3));
  const maxIntegerLength = max.length === Number.NaN || max.length === undefined ? max.toFixed(0).length : max.length;

  const estimatedDecimalsLength = {
    true: 0,
    [maxDeviation <= 3 && maxDeviation > 1]: 1,
    [maxDeviation <= 1 && maxDeviation > 0.5]: 2,
    [maxDeviation <= 0.5]: 3
  }.true;

  const axisLineDistanceFromValues = 10;
  const axisLabelWidth = 24;
  const maxAxisValueWidth = (maxIntegerLength + estimatedDecimalsLength) * 7;
  return axisLineDistanceFromValues + maxAxisValueWidth + axisLabelWidth;
};

const getEquallyDistributedTicks = timeWindow => {
  const from = moment(timeWindow.from).valueOf();
  const to = moment(timeWindow.to).valueOf();

  const diff = (to - from) / 8;
  return [0, 1, 2, 3, 4, 5, 6, 7, 8].map(num => timeFromNow(to, num * diff));
};

const getYAxisDomain = value => (value === null || value === undefined ? 'auto' : value);
const formatXAxis = tick => moment(tick).format('LT');
const formatYAxis = sensor => tick => {
  const decimalPlace =
    (sensor.is_custom && getNDecimalPlaces(sensor.decimal_place)) ||
    (getSensorPropsFromType(sensor.type) && getSensorPropsFromType(sensor.type).decimalPlace) ||
    2;
  return getRoundedValue(tick, decimalPlace);
};
const formatYAxisRounded = sensor => tick => {
  let decimalPlace = 1;
  if (!isNullOrUndefined(parseFloatDefault(`${tick}`)) && (tick === 0 || Math.floor(tick) !== 0)) {
    decimalPlace = 0;
  }
  return getSensorValueYAxis(sensor, tick, decimalPlace, true);
};
const tickStyle = { fontSize: 11 };

const getYAxisDomainBySensor = sensor => {
  let sensorData = sensor;
  if (!isNullOrUndefined(sensorData) && !sensorData.is_custom) {
    sensorData = { ...getSensorPropsFromType(sensor.type), ...sensor };
  }
  return [
    getYAxisDomain(((sensorData || {}).visualization || {}).yMin),
    getYAxisDomain(((sensorData || {}).visualization || {}).yMax)
  ];
};

// A valid time range => 30 minutes
const isValidTimeRange = (startDate, endDate) => {
  const start = moment(startDate).valueOf();
  const end = moment(endDate).valueOf();
  const diff = end - start;

  return diff + 120000 >= 60 * 1000 * 30;
};

const getTimeWindowAfterBrushChange = (oldIndices, newIndices, scopedTimeWindow) => {
  if (newIndices.startIndex !== oldIndices.startIndex) {
    return {
      from: moment(moment(scopedTimeWindow.to).valueOf() - 30 * 60 * 1000).toISOString(),
      to: scopedTimeWindow.to
    };
  }
  if (newIndices.endIndex !== oldIndices.endIndex) {
    return {
      from: scopedTimeWindow.from,
      to: moment(moment(scopedTimeWindow.from).valueOf() + 30 * 60 * 1000).toISOString()
    };
  }
  return {
    from: scopedTimeWindow.from,
    to: scopedTimeWindow.to
  };
};

const getClosestTime = (chartData, goalTime) =>
  chartData.reduce((prev, curr) => (Math.abs(curr - goalTime) < Math.abs(prev - goalTime) ? curr : prev));

const getChartGraphIndex = (chartData, time, type) => {
  const index = chartData.findIndex(item => item === time);
  if (index !== -1) {
    return index;
  }
  const closestTime = getClosestTime(chartData, time);
  if (closestTime) {
    return chartData.findIndex(item => item === closestTime);
  }

  if (type === 'start') {
    return 0;
  }

  return chartData.length - 1;
};

const ScopedTimeRangeLoader = () => (
  <div
    style={{
      position: 'absolute',
      top: '-1rem',
      right: 0,
      paddingRight: '1rem'
    }}
  >
    <Loader size={15} />
  </div>
);

const CustomTick = ({ x, y, payload, index, lastIndex, spanValue }) => {
  const date = moment(payload?.value).format('DD/MM/YYYY');
  const time = moment(payload?.value).format('HH:mm:ss');

  let tspanDx = spanValue.othersIndex;

  if (index === 0) {
    tspanDx = spanValue.fisrtIndex;
  } else if (index === lastIndex) {
    tspanDx = spanValue.lastIndex;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="middle" fill="#666" style={{ fontSize: '0.7rem' }}>
        <tspan x="0" dy="1em" dx={tspanDx}>
          {date}
        </tspan>
        <tspan x="0" dy="1em" dx={tspanDx}>
          {time}
        </tspan>
      </text>
    </g>
  );
};

CustomTick.propTypes = {
  x: T.number,
  y: T.number,
  payload: T.shape({
    value: T.oneOfType([T.string, T.number])
  }),
  index: T.number,
  lastIndex: T.number,
  spanValue: T.shape({
    fisrtIndex: T.number,
    lastIndex: T.number,
    othersIndex: T.number
  })
};

CustomTick.defaultProps = {
  x: 0,
  y: 0,
  payload: { value: 0 },
  index: 0,
  lastIndex: 0,
  spanValue: {
    fisrtIndex: 0,
    lastIndex: 0,
    othersIndex: 0
  }
};

const CustomTickChart = ({ x, y, payload, index }) => {
  const date = moment(payload?.value).format('DD/MM/YYYY');
  const time = moment(payload?.value).format('HH:mm:ss');

  const tspanDx = index * 10;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="middle" fill="#666" style={{ fontSize: '0.7rem' }}>
        <tspan x="0" dy="1em" dx={tspanDx}>
          {date}
        </tspan>
        <tspan x="0" dy="1em" dx={tspanDx}>
          {time}
        </tspan>
      </text>
    </g>
  );
};

CustomTickChart.propTypes = {
  x: T.number,
  y: T.number,
  payload: T.shape({
    value: T.oneOfType([T.string, T.number])
  }),
  index: T.number
};

CustomTickChart.defaultProps = {
  x: 0,
  y: 0,
  payload: { value: 0 },
  index: 0
};

export {
  MetricCustomSensorTooltip,
  MetricsCustomSensorCompareTooltip,
  CustomMachineTooltip,
  createReferenceChartData,
  getMachineStatusByTime,
  getMachineWarningStatusByTime,
  timeFromNow,
  calcYAxisWidth,
  formatXAxis,
  formatYAxis,
  formatYAxisRounded,
  tickStyle,
  getYAxisDomain,
  getYAxisDomainBySensor,
  getEquallyDistributedTicks,
  isValidTimeRange,
  getTimeWindowAfterBrushChange,
  getClosestTime,
  getChartGraphIndex,
  ScopedTimeRangeLoader,
  BatchMetricsCustomSensorCompareTooltip,
  getPredefinedYAxis,
  CustomTick,
  CustomTickChart
};
