import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';

import { ContentPageHeader } from 'web-components';
import T from 'prop-types';

import { ContentWrapper } from 'elements';
import { ROUTE_MACHINES } from 'attrs/routePaths';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DetailsWrapper } from './MachinesOverviewDetails.styled';
import InfoBlocks from './InfoBlocks';
import { BatchResponse } from '../../helpers/propTypes';
import { getUserRole } from '../../redux/user/selectors';
import { ROLE_MANAGER } from '../../attrs/roles';
import EditMachineDialog from './EditMachineDialog';
import { setConfirmationRequest } from '../../redux/ui/confirmations/actions';
import { loadMachine, showMachineEditModal, updateMachine } from '../../redux/machines/actions';
import { getSelectedMachine, getShowMachineEditModal } from '../../redux/machines/selectors';
import { isSuccess } from '../../helpers/utils';
import { MACHINE_STATUS_TRACKED, MACHINE_STATUS_UNTRACKED } from '../MachineTrackStatusIcon/MachineTrackStatusIcon';

const MachinesOverviewDetails = ({ machine }) => {
  const { t } = useTranslation();
  const { updateLoadingState } = useSelector(getSelectedMachine);
  const theme = useTheme();
  const dispatch = useDispatch();
  const data = machine;
  const isOpen = useSelector(getShowMachineEditModal);
  const title = t('machines.detail.title');
  const name = data.commission_number ? `${data.commission_number} ${title}` : title;
  const role = useSelector(getUserRole);
  const batchEnabled = true;
  const fullEdit = role === ROLE_MANAGER && batchEnabled;

  /**
   * Check if machine is tracked or not
   * @type {string}
   */
  data.isTracked = data.name !== '' && data.production_line !== '' ? MACHINE_STATUS_TRACKED : MACHINE_STATUS_UNTRACKED;

  /**
   * Open/Close machine edit modal
   * @param open
   * @constructor
   */
  const OpenEditMachineModal = open => {
    dispatch(showMachineEditModal(open));
  };

  useEffect(() => {
    if (isSuccess(updateLoadingState.status)) {
      OpenEditMachineModal(false);
      dispatch(loadMachine(data.id));
    } // eslint-disable-next-line
  }, [dispatch]);

  /**
   * Handle create machine edit
   * @returns {(function(): void)|*}
   * @param values
   */
  const handleSubmitMachine = values => {
    const current = values;
    // Just to prevent that user force the change of the field in screen;
    // The field has no link with the form, but is better to guarantee
    current.commission_number = data.commission_number;
    dispatch(updateMachine(current.id, current));
    // OpenEditMachineModal(false);
  };

  /**
   * Handler for closing modal
   * @param values Formik return values
   * @param handleReset Form values control
   * @returns {(function(): void)|*}
   * @constructor
   */
  const CloseCreateNoteModal = (values, handleReset) => () => {
    if (
      data &&
      (values.type !== data.type ||
        values.name !== data.name ||
        values.production_line !== data.production_line ||
        values.mechanical_config.bead_material !== data.mechanical_config.bead_material ||
        values.mechanical_config.beads_filling_level !== data.mechanical_config.beads_filling_level ||
        values.mechanical_config.beads_size_1 !== data.mechanical_config.beads_size_1 ||
        values.mechanical_config.beads_size_2 !== data.mechanical_config.beads_size_2 ||
        values.mechanical_config.grinding_technology !== data.mechanical_config.grinding_technology ||
        values.mechanical_config.inner_liner_material !== data.mechanical_config.inner_liner_material ||
        values.mechanical_config.samba_system !== data.mechanical_config.samba_system ||
        values.mechanical_config.sieve_size !== data.mechanical_config.sieve_size)
    ) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => {
            handleReset();
            OpenEditMachineModal(false);
          }
        })
      );
    } else {
      handleReset();
      OpenEditMachineModal(false);
    }
  };

  /**
   * Handle edit button click
   */
  const onClickEdit = () => {
    OpenEditMachineModal(true);
  };

  return (
    <>
      <ContentWrapper maxWidth={false}>
        <ContentPageHeader header={name} backUrl={`/${ROUTE_MACHINES}`} consistentHeight />
        <DetailsWrapper theme={theme}>
          <InfoBlocks data={data} hasBatch={batchEnabled} onClickEdit={onClickEdit} />
        </DetailsWrapper>
      </ContentWrapper>
      <EditMachineDialog
        isOpen={isOpen}
        handleCLose={CloseCreateNoteModal}
        handleFormSubmit={handleSubmitMachine}
        machine={data}
        fullEdit={fullEdit}
      />
    </>
  );
};

MachinesOverviewDetails.propTypes = {
  machine: T.shape(BatchResponse).isRequired
};

export default MachinesOverviewDetails;
